import { useTenantContext } from "@/contexts/TenantContext";
import { Document } from "@/state/queries/documents";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateDocumentRevisionInputs {
  document: Document;
  file: File | null;
}

// TODO Add cleanup if mutation fails partway
const createDocumentRevision = async (
  input: CreateDocumentRevisionInputs,
  tenantId: string
) => {
  if (!input.file) {
    throw new Error("File is required");
  }

  // Create placeholder document revision
  const { data: revisionData, error: revisionError } = await supabase
    .from("document_revisions")
    .insert({
      tenant_id: tenantId,
      document_id: input.document.id,
      file_path: "placeholder",
      storage_type: "file_storage",
      content_type: input.file.type,
      original_filename: input.file.name,
    })
    .select("id")
    .single();

  if (revisionError) {
    throw revisionError;
  }

  if (!revisionData) {
    throw new Error("Failed to create document revision");
  }

  // Upload file to supabase storage under revision ID
  const filePath = `${tenantId}/${revisionData.id}/${input.file.name}`;
  const { error: fileError } = await supabase.storage
    .from("documents")
    .upload(filePath, input.file);

  if (fileError) {
    throw fileError;
  }

  // Update document revision with file path
  const { error: updateError } = await supabase
    .from("document_revisions")
    .update({ file_path: filePath })
    .eq("id", revisionData.id);

  if (updateError) {
    throw updateError;
  }
};

export const useCreateDocumentRevision = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: CreateDocumentRevisionInputs) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createDocumentRevision(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
