import NotFoundPage from "@/components/NotFoundPage";
import { RouterContext } from "@/infrastructure/Router";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import React from "react";

// NOTE: Editing anything in this file tends to break the whole app. Proceed with caution.
const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Root,
  notFoundComponent: () => (
    <NotFoundPage description="The page you are looking for does not exist or you do not have access to it." />
  ),
});

function Root() {
  return (
    <>
      <Outlet />
      <TanStackRouterDevtools />
    </>
  );
}
