import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface DeleteDocumentInput {
  id: string;
  tenantId: string;
}

const deleteDocument = async (input: DeleteDocumentInput) => {
  const { error: revisionError } = await supabase
    .from("document_revisions")
    .delete()
    .eq("document_id", input.id)
    .eq("tenant_id", input.tenantId);

  if (revisionError) {
    throw revisionError;
  }

  const { error } = await supabase
    .from("documents")
    .delete()
    .eq("id", input.id)
    .eq("tenant_id", input.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: DeleteDocumentInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteDocument(input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
