import SaveProcessDialog from "@/components/dialogs/SaveProcessDialog";
import MeridianTabNav from "@/components/MeridianTabNav";
import PageTitle from "@/components/PageTitle";
import { useDocumentsByProcess } from "@/state/queries/documents";
import { processesQueryOptions, useProcess } from "@/state/queries/processes";
import { Box, Button, Flex } from "@radix-ui/themes";
import {
  IconArrowRightRhombus,
  IconCertificate,
  IconFileText,
  IconId,
  IconListDetails,
  IconPencil,
  IconX,
} from "@tabler/icons-react";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { z } from "zod";
export const Route = createFileRoute("/_app/$tenantSlug/processes/$processId")({
  validateSearch: z.object({
    isEditing: z.boolean().optional(),
  }),
  beforeLoad: async ({ context, params }) => {
    if (!context.tenant) {
      return { getTitle: () => "Process Details" };
    }
    const processes = await context.queryClient.ensureQueryData(
      processesQueryOptions(context.tenant.tenantId)
    );
    const process = processes.find((p) => p.id === params.processId);
    return {
      getTitle: () => process?.name ?? "Process Details",
    };
  },
  component: Process,
});

function Process() {
  const { tenantSlug, processId } = Route.useParams();
  const { data: process, isLoading } = useProcess(processId);
  const { data: documents } = useDocumentsByProcess(processId);
  const { isEditing } = Route.useSearch();
  const navigate = Route.useNavigate();

  const toggleEditing = () =>
    navigate({
      search: { isEditing: isEditing === true ? undefined : true },
      replace: true,
    });

  return (
    <Flex direction="column" gap="5">
      <PageTitle
        title={process?.name ?? "Process"}
        description={process?.description}
        tag={process?.id ?? "Process"}
        isLoading={isLoading}
      >
        <Flex align="center" gap="2">
          {!isEditing && (
            <Button variant="soft" color="gray" onClick={toggleEditing}>
              <IconPencil />
              Edit
            </Button>
          )}
          {isEditing && (
            <>
              <Button variant="soft" color="gray" onClick={toggleEditing}>
                <IconX />
                Cancel
              </Button>
              <SaveProcessDialog onSave={toggleEditing} />
            </>
          )}
        </Flex>
      </PageTitle>
      <Box>
        <MeridianTabNav<"/$tenantSlug/processes/$processId">
          childRouteParams={{ tenantSlug, processId }}
          links={[
            {
              to: "/$tenantSlug/processes/$processId/",
              label: "Overview",
              icon: <IconId />,
            },
            {
              to: "/$tenantSlug/processes/$processId/workflows",
              label: "Workflows",
              icon: <IconArrowRightRhombus />,
            },
            {
              to: "/$tenantSlug/processes/$processId/record-types",
              label: "Record Types",
              icon: <IconListDetails />,
            },
            {
              to: "/$tenantSlug/processes/$processId/documents",
              label: "Documents",
              icon: <IconFileText />,
              badge: documents?.length.toString() ?? undefined,
            },
            {
              to: "/$tenantSlug/processes/$processId/compliance",
              label: "Compliance",
              icon: <IconCertificate />,
            },
          ]}
        />
        <Outlet />
      </Box>
    </Flex>
  );
}

export default Process;
