import PageTitle from "@/components/PageTitle";
import { Record as DbRecord, RECORD_TYPES } from "@/demoData";
import { supabaseRecords } from "@/supabaseClient";
import { Box, Flex, Skeleton } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/records/$recordSlug/$recordId"
)({
  beforeLoad: () => {
    return {
      getTitle: () => "Record Details",
    };
  },
  component: Record,
});

function Record() {
  const { recordSlug, recordId } = Route.useParams();
  const [record, setRecord] = useState<DbRecord | null>(null);
  const recordType = recordSlug ? RECORD_TYPES[recordSlug] : undefined;

  useEffect(() => {
    const fetchRecord = async () => {
      if (!recordType || !recordId) return;

      const { data, error } = await supabaseRecords
        .schema("records")
        .from(recordType.databaseTable)
        .select("*")
        .eq("id", recordId)
        .single();

      if (error) {
        console.error("Error fetching record:", error);
      } else {
        setRecord(data);
      }
    };

    fetchRecord();
  }, [recordType, recordId]);

  const isLoaded = recordType && record;

  return (
    <Flex direction="column" gap="5">
      <PageTitle
        title={isLoaded ? recordType.name : <Skeleton>Record</Skeleton>}
        description={
          isLoaded ? recordType.description : <Skeleton>Record</Skeleton>
        }
        tag="Record"
      />
      <Box>
        {isLoaded ? (
          <pre>{JSON.stringify(record, null, 2)}</pre>
        ) : (
          <Skeleton>Record details</Skeleton>
        )}
      </Box>
    </Flex>
  );
}

export default Record;
