import { Flex, Text } from "@radix-ui/themes";
import { IconAlertCircleFilled } from "@tabler/icons-react";

export const FormError: React.FC<{
  message: string | undefined;
}> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Flex align="center" gap="1">
      <IconAlertCircleFilled color="var(--red-10)" />
      <Text color="red" size="2" weight="bold">
        {message}
      </Text>
    </Flex>
  );
};
