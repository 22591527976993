import { Flex, Spinner, Text } from "@radix-ui/themes";
import { FieldMeta } from "@tanstack/react-form";
import { PropsWithChildren } from "react";
import { FormError } from "./FormError";
import { FormFieldProps } from "./types";
import { hasError } from "./utils";

export const FormField: React.FC<PropsWithChildren<FormFieldProps>> = ({
  reactFormField: formField,
  label,
  required,
  helperText,
  children,
}) => {
  const requiredAsterisk = required ? <Text color="red"> *</Text> : null;
  return (
    <Flex direction="column" gap="1">
      <label htmlFor={formField.name}>
        <Text size="3" weight="bold">
          {label}
        </Text>
        {requiredAsterisk}
      </label>
      {children}
      <FormFieldValidation fieldMeta={formField.state.meta} />
    </Flex>
  );
};

const FormFieldValidation: React.FC<{
  fieldMeta: FieldMeta;
}> = ({ fieldMeta }) => {
  let firstError;
  if (hasError(fieldMeta)) {
    firstError = <FormError message={fieldMeta.errors[0]?.toString()} />;
  }
  return (
    <Spinner loading={fieldMeta.isValidating}>
      {firstError ? firstError : fieldMeta.isValidating ? "Validating..." : ""}
    </Spinner>
  );
};
