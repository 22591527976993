import {
  Button,
  Dialog,
  Flex,
  Inset,
  ScrollArea,
  Table,
  Text,
} from "@radix-ui/themes";
import React, { useState } from "react";

const gapAnalysisData = [
  {
    requirement: "4.1",
    name: "Understanding the organization and its context",
    description:
      "Determine external and internal issues relevant to its purpose and strategic direction",
    currentProcess: "Annual SWOT analysis",
    changesRequired: "Implement quarterly reviews of external factors",
  },
  {
    requirement: "4.2",
    name: "Understanding the needs and expectations of interested parties",
    description:
      "Determine interested parties and their requirements relevant to the QMS",
    currentProcess: "Customer feedback surveys",
    changesRequired: "Expand to include suppliers and regulatory bodies",
  },
  {
    requirement: "4.3",
    name: "Determining the scope of the quality management system",
    description: "Determine the boundaries and applicability of the QMS",
    currentProcess: "Documented in quality manual",
    changesRequired: "Update to include new product lines",
  },
  {
    requirement: "8.3.1",
    name: "Design and development planning",
    description:
      "Plan, implement and control the design and development process",
    currentProcess: "Ad-hoc project planning",
    changesRequired:
      "Implement structured design and development planning process",
  },
  {
    requirement: "8.3.2",
    name: "Design and development inputs",
    description:
      "Determine requirements essential for the specific types of products and services",
    currentProcess: "Customer requirements gathering",
    changesRequired:
      "Expand inputs to include regulatory and functional requirements",
  },
  {
    requirement: "8.3.3",
    name: "Design and development controls",
    description: "Apply controls to the design and development process",
    currentProcess: "Design reviews at project milestones",
    changesRequired: "Implement verification and validation activities",
  },
  {
    requirement: "8.3.4",
    name: "Design and development outputs",
    description:
      "Ensure outputs meet input requirements and are adequate for subsequent processes",
    currentProcess: "Final design documentation",
    changesRequired: "Implement formal output review process",
  },
  {
    requirement: "8.3.6",
    name: "Design and development changes",
    description:
      "Identify, review and control changes made during or after design and development",
    currentProcess: "Change request system",
    changesRequired:
      "Enhance change control process to include impact analysis",
  },
  {
    requirement: "8.3.4.1",
    name: "Design and development testing",
    description:
      "Ensure that tests are planned, controlled, reviewed, and documented",
    currentProcess: "Ad-hoc testing procedures",
    changesRequired: "Develop comprehensive test plans and documentation",
  },
  {
    requirement: "8.3.5.1",
    name: "Production process verification",
    description: "Implement production process verification activities",
    currentProcess: "Limited process verification",
    changesRequired: "Establish formal first article inspection process",
  },
  {
    requirement: "8.3.6.2",
    name: "Production process changes",
    description:
      "Control and document changes affecting processes, production equipment, tools or software programs",
    currentProcess: "Informal process change notifications",
    changesRequired: "Establish formal process change control system",
  },
];

const GapAnalysisDialog: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button variant="soft">View gap analysis</Button>
      </Dialog.Trigger>
      <Dialog.Content style={{ maxWidth: "90vw", width: "900px" }}>
        <Dialog.Title>AS9100 Gap Analysis</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Identified gaps between your organization's processes and the AS9100
          standard.
        </Dialog.Description>
        <Inset side="x">
          <ScrollArea style={{ height: "400px" }}>
            <Table.Root size="1">
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>Num</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Requirement</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>
                    Current Process
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>
                    Changes Required
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {gapAnalysisData.map((item) => (
                  <Table.Row key={item.requirement}>
                    <Table.Cell>
                      <Text weight="bold">{item.requirement}</Text>
                    </Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.description}</Table.Cell>
                    <Table.Cell>{item.currentProcess}</Table.Cell>
                    <Table.Cell>{item.changesRequired}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </ScrollArea>
        </Inset>
        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default GapAnalysisDialog;
