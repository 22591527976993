import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useEditDocument } from "@/state/mutations/documents/editDocument";
import { Document } from "@/state/queries/documents";
import { Dialog, Flex } from "@radix-ui/themes";
import { IconPencil } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";

interface EditDocumentFormInputs {
  name: string;
  description: string;
  owner: string;
  processId: string;
  // Can only be set if document is in Draft status
  file: File | undefined;
}

export const EditDocumentMetadataAction: React.FC<{
  document: Document;
}> = ({ document }) => {
  const [open, setOpen] = useState(false);
  const { mutateAsync: editDocument, reset: resetMutation } = useEditDocument();

  const form = useForm<EditDocumentFormInputs, any>({
    defaultValues: {
      name: document.title,
      description: document.description ?? "",
      owner: document.owner,
      processId: document.processId ?? "",
      file: undefined,
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        name: z.string().min(1, { message: "Document name is required" }),
        description: z.string(),
        owner: z.string(),
        processId: z.string(),
      }),
    },
    onSubmit: async ({ value }) => {
      await editDocument(
        {
          id: document.id,
          name: value.name,
          description: value.description,
          owner: value.owner,
          processId: value.processId,
          file: value.file,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetMutation();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton
          permission="write.documents"
          variant="soft"
          color="gray"
        >
          <IconPencil />
          Edit
        </PermissionedButton>
      </Dialog.Trigger>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Edit document</Dialog.Title>
        <Form.Root reactForm={form}>
          <Flex direction="column" gap="4">
            <Form.Fields>
              <Form.TextField<EditDocumentFormInputs>
                reactFormField={{ form, name: "name" }}
                fieldProps={{ label: "Document title", required: true }}
                placeholder="Enter document title"
                required
              />
              <Form.TextArea<EditDocumentFormInputs>
                reactFormField={{ form, name: "description" }}
                fieldProps={{ label: "Description" }}
              />
              <Form.UserSelect<EditDocumentFormInputs>
                reactFormField={{ form, name: "owner" }}
                fieldProps={{ label: "Owner" }}
              />
              <Form.ProcessSelect<EditDocumentFormInputs>
                reactFormField={{ form, name: "processId" }}
                fieldProps={{ label: "Parent Process" }}
              />
              {document.status === "Draft" && (
                <Form.FileUpload<EditDocumentFormInputs>
                  reactFormField={{ form, name: "file" }}
                  fieldProps={{ label: "Update document file" }}
                />
              )}
            </Form.Fields>
            <form.Subscribe
              selector={(state) => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => (
                <>
                  <Flex gap="3" justify="end">
                    <Form.CancelButton form={form} />
                    <Form.SubmitButton
                      canSubmit={canSubmit}
                      isSubmitting={isSubmitting}
                    >
                      Edit document
                    </Form.SubmitButton>
                  </Flex>
                </>
              )}
            />
          </Flex>
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
