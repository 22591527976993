import { ComplianceRequirement } from "@/state/queries/complianceFrameworks";
import { Box, Dialog, ScrollArea, Tabs } from "@radix-ui/themes";
import { IconFile } from "@tabler/icons-react";
import ReactMarkdown from "react-markdown";
import NonIdealState from "../NonIdealState";

interface RequirementDialogProps {
  requirement?: ComplianceRequirement;
  onClose: () => void;
}

export const RequirementDialog = ({
  requirement,
  onClose,
}: RequirementDialogProps) => {
  if (!requirement) {
    return null;
  }
  return (
    <Dialog.Root
      open={!!requirement}
      onOpenChange={(open) => !open && onClose()}
    >
      <Dialog.Content>
        <Dialog.Title>
          {requirement?.clause}: {requirement?.title}
        </Dialog.Title>
        <Tabs.Root defaultValue="requirement">
          <Tabs.List>
            <Tabs.Trigger value="requirement">Requirement</Tabs.Trigger>
            <Tabs.Trigger value="resources">Mapped Resources</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="requirement">
            <RequirementTab requirement={requirement} />
          </Tabs.Content>
          <Tabs.Content value="resources">
            <MappedResourcesTab />
          </Tabs.Content>
        </Tabs.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const TAB_HEIGHT = "300px";

function RequirementTab({
  requirement,
}: {
  requirement: ComplianceRequirement;
}) {
  return (
    <ScrollArea style={{ height: "300px" }}>
      <Box pr="4">
        <ReactMarkdown
          // Add lower-alpha list style to match AS9100 standard
          components={{
            ol: ({ children }) => {
              return <ol style={{ listStyle: "lower-alpha" }}>{children}</ol>;
            },
          }}
        >
          {requirement?.description || ""}
        </ReactMarkdown>
      </Box>
    </ScrollArea>
  );
}

function MappedResourcesTab() {
  return (
    <Box height={TAB_HEIGHT}>
      <NonIdealState icon={<IconFile />} title="No resources mapped" />
    </Box>
  );
}
