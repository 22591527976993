import { useTenantUsers } from "@/state/queries/tenantUsers";
import { Select } from "@radix-ui/themes";
import { PropsWithChildren } from "react";
import { BaseSelect, BaseSelectProps } from "./BaseSelect";

export interface UserSelectProps<TData> extends BaseSelectProps<TData> {}

export function UserSelect<TData>(
  props: PropsWithChildren<UserSelectProps<TData>>
) {
  const { data: users } = useTenantUsers();

  return (
    <BaseSelect {...props}>
      <Select.Content>
        {Object.values(users ?? {}).map((user) => (
          <Select.Item key={user.userId} value={user.userId}>
            {user.fullName ?? user.email}
          </Select.Item>
        ))}
      </Select.Content>
    </BaseSelect>
  );
}
