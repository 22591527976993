import { Flex } from "@radix-ui/themes";
import React from "react";
import NonIdealState from "./NonIdealState";

export interface EmptyPageProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  action?: React.ReactNode;
}

const EmptyPage: React.FC<EmptyPageProps> = ({
  icon,
  title,
  description,
  action,
}) => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      style={{ height: "100vh", width: "100%" }}
    >
      <NonIdealState
        icon={icon}
        title={title}
        description={description}
        action={action}
      />
    </Flex>
  );
};

export default EmptyPage;
