import { useAuth } from "@/contexts/AuthContext";
import { Tenants, useTenants } from "@/state/queries/tenants";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

/**
 * Redirects user to the appropriate tenant if user is already authenticated.
 */
export function useTenantRedirect(redirectTo?: string) {
  const { isLoaded, isAuthenticated } = useAuth();
  const tenants = useTenants();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && isAuthenticated && tenants.data) {
      if (redirectTo) {
        navigate({ to: redirectTo, replace: true });
      } else {
        const tenantRedirect = getTenantRedirect(tenants.data);
        if (tenantRedirect) {
          navigate({ to: tenantRedirect, replace: true });
        }
      }
    }
  }, [isLoaded, isAuthenticated, tenants.data, navigate, redirectTo]);
}

const TENANT_LOCAL_STORAGE_KEY = "lastUsedTenantSlug";
/**
 * Given the user's tenants, return the URL path the user should be redirected to.
 * Defaults to the last used tenant if present, else the first tenant in the result.
 * Falls back to the no-account page if there are no tenants.
 */
const getTenantRedirect = (tenants: Tenants) => {
  const lastUsedTenantSlug = localStorage.getItem(TENANT_LOCAL_STORAGE_KEY);
  if (lastUsedTenantSlug && tenants[lastUsedTenantSlug]) {
    return `/${lastUsedTenantSlug}`;
  }

  if (Object.keys(tenants).length > 0) {
    return `/${Object.keys(tenants)[0]}`;
  }
};

/**
 * Redirects user to the no-account page if user has no tenants.
 */
export function useNoTenantRedirect() {
  const navigate = useNavigate();
  const tenants = useTenants();

  useEffect(() => {
    if (tenants.data && Object.keys(tenants.data).length === 0) {
      navigate({ to: "/no-account", replace: true });
    }
  }, [tenants.data, navigate]);
}
