import DetailCard from "@/components/DetailCard";
import List from "@/components/List";
import NonIdealState from "@/components/NonIdealState";
import TabTitle from "@/components/TabTitle";
import User from "@/components/User";
import { useDocumentsByProcess } from "@/state/queries/documents";
import { useProcess } from "@/state/queries/processes";
import {
  Badge,
  Button,
  DataList,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import {
  IconArrowRightRhombus,
  IconCalendar,
  IconChevronRight,
  IconFile,
  IconFilePlus,
  IconFileText,
  IconId,
  IconPencil,
  IconSquareCheck,
} from "@tabler/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";

export const Route = createFileRoute("/_app/$tenantSlug/processes/$processId/")(
  {
    // Empty because the parent route sets the title
    beforeLoad: () => ({
      getTitle: () => "",
    }),
    component: ProcessOverview,
  }
);

function ProcessOverview() {
  const { tenantSlug, processId } = Route.useParams();
  const { data: process } = useProcess(processId);
  const { data: documents } = useDocumentsByProcess(processId);
  const navigate = useNavigate();

  return (
    <>
      <TabTitle title="Overview" />
      <Grid columns="2" gap="4" maxWidth="900px">
        <DetailCard
          title="Basic Information"
          icon={<IconId />}
          actions={
            <Tooltip content="Edit information">
              <IconButton
                color="gray"
                variant="ghost"
                aria-label="Edit information"
              >
                <IconPencil />
              </IconButton>
            </Tooltip>
          }
        >
          <DataList.Root>
            <DataList.Item>
              <DataList.Label>Process Name</DataList.Label>
              <DataList.Value>{process?.name}</DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Process Owner</DataList.Label>
              <DataList.Value>
                {process?.ownedBy ? (
                  <User userId={process.ownedBy} />
                ) : (
                  <Text color="gray">No owner</Text>
                )}
              </DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Department</DataList.Label>
              <DataList.Value>
                <Badge>Engineering</Badge>
              </DataList.Value>
            </DataList.Item>
            <DataList.Item>
              <DataList.Label>Created On</DataList.Label>
              <DataList.Value>
                <Flex align="center" gap="1">
                  <IconCalendar />
                  <Text>
                    {dayjs(process?.createdAt).format("MMM DD, YYYY")}
                  </Text>
                </Flex>
              </DataList.Value>
            </DataList.Item>
          </DataList.Root>
        </DetailCard>
        <DetailCard title="Workflow" icon={<IconArrowRightRhombus />}>
          <List.Root>
            <List.Item>
              <Flex align="center" justify="between" width="100%">
                <Flex align="center" gap="1">
                  <IconArrowRightRhombus />
                  <Text>Design Plans</Text>
                </Flex>
                <IconChevronRight />
              </Flex>
            </List.Item>
          </List.Root>
        </DetailCard>
        <DetailCard
          title="Documents"
          icon={<IconFileText />}
          actions={
            <>
              <Tooltip content="Add document">
                <IconButton
                  size="1"
                  aria-label="Add document"
                  color="gray"
                  variant="ghost"
                >
                  <IconFilePlus />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          {documents && documents.length > 0 ? (
            <List.Root>
              {documents.map((doc) => (
                <List.Item
                  key={doc.id}
                  onClick={() => {
                    navigate({
                      to: `/${tenantSlug}/documents/${doc.id}`,
                    });
                  }}
                >
                  <Flex align="center" justify="between" width="100%">
                    <Flex align="center" gap="1">
                      <IconFileText />
                      <Text>{doc.title}</Text>
                    </Flex>
                    <IconChevronRight />
                  </Flex>
                </List.Item>
              ))}
            </List.Root>
          ) : (
            <NonIdealState
              key="no-documents"
              size="small"
              icon={<IconFile />}
              title="No documents found"
              description="Manage documents related to this process."
              action={
                <Button size="1" variant="soft">
                  <IconFilePlus />
                  Add document
                </Button>
              }
            />
          )}
        </DetailCard>
        <DetailCard title="Compliance" icon={<IconSquareCheck />}>
          <List.Root>
            <List.Item>
              <Flex align="center" justify="between" width="100%">
                <Flex align="center" gap="1">
                  <IconSquareCheck />
                  <Text>AS9100 Clause 8.3</Text>
                </Flex>
                <IconChevronRight />
              </Flex>
            </List.Item>
          </List.Root>
        </DetailCard>
      </Grid>
    </>
  );
}
