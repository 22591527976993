import { RecordType } from "@/demoData";
import { supabaseRecords } from "@/supabaseClient";
import { useQuery } from "@tanstack/react-query";

async function fetchRecords(recordType: RecordType | undefined) {
  if (!recordType) {
    return [];
  }

  const { data, error } = await supabaseRecords
    .schema("records")
    .from(recordType.databaseTable)
    .select("*");

  if (error) {
    throw error;
  }

  return data || [];
}

export function useRecords(recordType: RecordType | undefined) {
  const { data: records = [], isLoading } = useQuery({
    queryKey: ["records", recordType?.slug],
    queryFn: () => fetchRecords(recordType),
    enabled: !!recordType,
    retry: false,
  });

  return { records, loading: isLoading };
}
