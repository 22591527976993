import { RecordField } from "@/demoData";
import { fetchDocumentFile } from "@/state/queries/documentFile";
import { THEME_COLOR } from "@/theme";
import { Badge, Box, Flex, Link, Text } from "@radix-ui/themes";
import { IconExternalLink } from "@tabler/icons-react";
import { ICellRendererParams } from "ag-grid-community";
import RelativeTime from "../RelativeTime";
import User from "../User";

export const getRendererForFieldType = (type: RecordField["type"]) => {
  switch (type) {
    case "enum":
      return BadgeRenderer;
    default:
      return TextRenderer;
  }
};

export const EmailRenderer = (props: ICellRendererParams) => {
  return (
    <Link href={`mailto:${props.data.point_of_contact_email}`}>
      {props.data.point_of_contact_email}
    </Link>
  );
};

export const EmptyValue = ({ text = "No value" }: { text?: string }) => (
  <Text style={{ fontStyle: "italic" }} color="gray">
    {text}
  </Text>
);

export const WebsiteRenderer = (props: ICellRendererParams) => {
  if (!props.data.website) {
    return <EmptyValue />;
  }
  return (
    <Flex align="center" height="100%">
      <WebsiteLink url={props.data.website} />
    </Flex>
  );
};

export const WebsiteLink = ({ url }: { url?: string | null }) => {
  if (!url) {
    return <EmptyValue />;
  }
  return (
    <Link href={url} target="_blank">
      <Flex align="center" gap="1" height="100%">
        <Text>{url.replace(/^(https?:\/\/)?(www\.)?/, "")} </Text>
        <IconExternalLink />
      </Flex>
    </Link>
  );
};

export const BadgeRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue text="-" />;
  }

  // Hack: ICellRendererParams can't handle cellRendererParams
  let color = (props as any).color;
  if (!color) {
    if (
      props.value === "Approved" ||
      props.value === "Released" ||
      props.value === "Active"
    ) {
      color = "green";
    } else if (
      props.value === "Pending review" ||
      props.value === "In review"
    ) {
      color = THEME_COLOR;
    } else {
      color = "gray";
    }
  }

  return (
    <Badge color={color ?? "iris"} size="2" radius="large">
      {props.value}
    </Badge>
  );
};

export const StatusRenderer = (props: ICellRendererParams) => {
  return <Status status={props.value} />;
};

export const Status = ({ status }: { status: string }) => {
  return (
    <Badge
      color={
        status === "Active"
          ? "green"
          : status === "Pending"
            ? THEME_COLOR
            : "gray"
      }
      size="2"
      radius="large"
    >
      <Box
        width="6px"
        height="6px"
        style={{
          backgroundColor: "var(--accent-a11)",
          borderRadius: "100%",
        }}
      />
      {status}
    </Badge>
  );
};

export const TextRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return (
    <Text>
      {props.formatValue ? props.formatValue(props.value) : props.value}
    </Text>
  );
};

export const FileLinkRenderer = (props: ICellRendererParams) => {
  // Creates signed URL on the fly to avoid having to create URLs for every link
  const handleClick = async () => {
    const signedUrl = await fetchDocumentFile(props.value);

    if (signedUrl) {
      window.open(signedUrl);
    }
  };

  if (!props.value) {
    return <EmptyValue />;
  }
  const displayName = props.value.split("/").pop();
  return (
    <Link
      onClick={handleClick}
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: "var(--space-1)",
        cursor: "pointer",
      }}
    >
      {displayName}
      <IconExternalLink />
    </Link>
  );
};

export const PlainLinkRenderer = (props: ICellRendererParams) => {
  return <Link underline="always">{props.value}</Link>;
};

export const UserRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue text="None" />;
  }
  return <User userId={props.value} />;
};

export const TimestampRenderer = (props: ICellRendererParams) => {
  if (!props.value) {
    return <EmptyValue />;
  }
  return <RelativeTime timestamp={props.value} />;
};
