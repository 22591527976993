import TabTitle from "@/components/TabTitle";
import { Workflow } from "@/components/workflow/Workflow";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/workflows"
)({
  beforeLoad: () => ({
    getTitle: () => "Workflows",
  }),
  component: ProcessWorkflows,
});

function ProcessWorkflows() {
  const { isEditing } = Route.useSearch();

  const cancelSemaphore = isEditing ? 0 : 1;

  return (
    <>
      <TabTitle title="Workflows" />
      <Workflow isEditing={!!isEditing} cancelSemaphore={cancelSemaphore} />
    </>
  );
}
