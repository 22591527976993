import { Button, Dialog } from "@radix-ui/themes";
import { FormApi } from "@tanstack/react-form";

export interface CancelButtonProps {
  form: FormApi<any, any>;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ form }) => {
  return (
    <Dialog.Close onClick={() => form.reset()}>
      <Button variant="soft" color="gray" size="3">
        Cancel
      </Button>
    </Dialog.Close>
  );
};
