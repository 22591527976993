import NonIdealState from "@/components/NonIdealState";
import { useDocumentFile } from "@/state/queries/documentFile";
import { DocumentRevision } from "@/state/queries/documents";
import { Box, Flex, IconButton, Spinner, Strong, Text } from "@radix-ui/themes";
import {
  IconChevronLeft,
  IconChevronRight,
  IconFileAlert,
} from "@tabler/icons-react";
import { useState } from "react";
import { Document as PDFDocument, Page } from "react-pdf";

export interface DocumentPreviewProps {
  documentRevision?: DocumentRevision;
  width: number;
  isLoading?: boolean;
}

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  documentRevision,
  width,
  isLoading,
}) => {
  const {
    data: documentFileUrl,
    isLoading: documentFileUrlIsLoading,
    error: documentFileUrlError,
  } = useDocumentFile(documentRevision?.filePath);

  if (isLoading || documentFileUrlIsLoading) {
    return <NonIdealState icon={<Spinner />} title="Loading document..." />;
  }

  if (documentFileUrlError) {
    return (
      <NonIdealState
        icon={<IconFileAlert />}
        title="Error creating URL to view document"
      />
    );
  }

  if (!documentRevision || !documentFileUrl) {
    return <NonIdealState icon={<IconFileAlert />} title="No document found" />;
  }

  if (MicrosoftDocumentTypes.has(documentRevision.contentType)) {
    return <MicrosoftDocumentViewer fileUrl={documentFileUrl} width={width} />;
  }

  if (documentRevision.contentType === "application/pdf") {
    return <PdfDocumentViewer fileUrl={documentFileUrl} width={width} />;
  }

  return (
    <NonIdealState icon={<IconFileAlert />} title="Unsupported document type" />
  );
};

const PdfDocumentViewer: React.FC<{ fileUrl: string; width: number }> = ({
  fileUrl,
  width,
}) => {
  const [hasError, setHasError] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const controls = !hasError && (
    <Flex
      align="center"
      justify="center"
      p="2"
      gap="3"
      position="sticky"
      bottom="4"
      style={{
        backgroundColor: "var(--color-surface)",
        border: "var(--line-border)",
        borderRadius: "var(--radius-2)",
        margin: "0 auto",
        maxWidth: "150px",
      }}
    >
      <IconButton
        variant="ghost"
        color="gray"
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        <IconChevronLeft />
      </IconButton>
      <Text size="2">
        Page <Strong>{pageNumber}</Strong> of <Strong>{numPages}</Strong>
      </Text>
      <IconButton
        variant="ghost"
        color="gray"
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
      >
        <IconChevronRight />
      </IconButton>
    </Flex>
  );

  // TODO handle this height better: move ScrollArea into this component
  const errorComponent = (
    <Flex height="350px">
      <NonIdealState icon={<IconFileAlert />} title="Error loading document" />
    </Flex>
  );

  return (
    <Box position="relative">
      <PDFDocument
        file={fileUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        error={errorComponent}
        onLoadError={() => setHasError(true)}
      >
        <Page
          pageNumber={pageNumber}
          width={width}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          error={errorComponent}
        />
      </PDFDocument>
      {controls}
    </Box>
  );
};

const MicrosoftDocumentTypes = new Set([
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
]);

const MicrosoftDocumentViewer: React.FC<{ fileUrl: string; width: number }> = ({
  fileUrl,
  width,
}) => {
  const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;

  return (
    <iframe
      src={officeViewerUrl}
      title="Microsoft Office Document Viewer"
      width={width}
      height="100%"
      frameBorder="0"
    />
  );
};
