import { processesQueryOptions } from "@/state/queries/processes";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/processes")({
  beforeLoad: () => {
    return {
      getTitle: () => "Processes",
    };
  },
  loader: ({ context }) => {
    // TODO clean this up
    if (!context.tenant) {
      return;
    }
    context.queryClient.ensureQueryData(
      processesQueryOptions(context.tenant.tenantId)
    );
  },
});
