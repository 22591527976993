import { Session } from "@supabase/supabase-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabaseClient";

export interface AuthContext {
  isAuthenticated: boolean;
  isLoaded: boolean;
  session: Session | undefined;
  logout: () => Promise<void>;
}

const AuthContextInner = createContext<AuthContext>({
  isAuthenticated: false,
  isLoaded: false,
  session: undefined,
  logout: async () => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session ?? undefined);
      setIsLoaded(true);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session ?? undefined);
    });

    return () => subscription.unsubscribe();
  }, []);

  const isAuthenticated = session !== undefined;

  const logout = async () => {
    await supabase.auth.signOut();
    setSession(undefined);
  };

  return (
    <AuthContextInner.Provider
      value={{ isAuthenticated, isLoaded, session, logout }}
    >
      {children}
    </AuthContextInner.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContextInner);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export function useSupabaseSession() {
  const auth = useAuth();
  if (auth.session == null) {
    return undefined;
  }
  return auth.session;
}
