import EmptyPage from "@/components/EmptyPage";
import { useAuth } from "@/contexts/AuthContext";

import { useTenantRedirect } from "@/hooks/useTenantRedirect";
import { useTenants } from "@/state/queries/tenants";
import { Button } from "@radix-ui/themes";
import { IconLogout, IconWorldOff } from "@tabler/icons-react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useState } from "react";

export const Route = createFileRoute("/no-account")({
  component: NoAccountFound,
});

function NoAccountFound() {
  const [logoutIsLoading, setLogoutIsLoading] = useState(false);

  useTenantRedirect();
  const { logout } = useAuth();
  const { refetch: refetchTenants } = useTenants();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    setLogoutIsLoading(true);
    await logout();
    await refetchTenants();
    navigate({ to: "/login" });
    setLogoutIsLoading(false);
  }, [logout, navigate, refetchTenants]);

  return (
    <EmptyPage
      icon={<IconWorldOff />}
      title="No account found"
      description="Your user account is not associated with any organizations. Please contact your Meridian administrator to get access."
      action={
        <Button onClick={handleLogout} loading={logoutIsLoading}>
          <IconLogout />
          Log out
        </Button>
      }
    />
  );
}
