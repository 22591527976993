import { TextField } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { FormField } from "./FormField";
import { BaseFieldProps } from "./types";
import { hasError } from "./utils";

export interface BaseTextFieldProps<TData>
  extends BaseFieldProps<TData>,
    TextField.RootProps {}

export function BaseTextField<TData>(props: BaseTextFieldProps<TData>) {
  const { reactFormField, fieldProps, children, ...rootProps } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  // Consider field required if either root or field props are required
  const required = rootProps.required || fieldProps.required;

  const error = hasError(field.state.meta);
  return (
    <FormField reactFormField={field} required={required} {...fieldProps}>
      <TextField.Root
        id={field.name as string}
        name={field.name as string}
        value={field.state.value as string}
        onBlur={field.handleBlur}
        onChange={(e) => field.handleChange(e.target.value as any)}
        color={error ? "red" : undefined}
        size="3"
        style={{
          boxShadow: error
            ? "inset 0 0 0 2px var(--text-field-focus-color)"
            : undefined,
        }}
        required={required}
        {...rootProps}
      >
        {children}
      </TextField.Root>
    </FormField>
  );
}
