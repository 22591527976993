import { supabase } from "@/supabaseClient";
import { skipToken, useQuery } from "@tanstack/react-query";

const FILE_URL_DURATION = 3600; // 1 hour
const FILE_URL_DURATION_MS = FILE_URL_DURATION * 1000;

// Exported for use in cell renderers
export const fetchDocumentFile = async (filePath: string) => {
  const { data, error } = await supabase.storage
    .from("documents")
    .createSignedUrl(filePath, FILE_URL_DURATION);

  if (error) {
    throw error;
  }

  return data.signedUrl;
};

export const useDocumentFile = (filePath: string | undefined) => {
  return useQuery({
    queryKey: ["documentFile", filePath],
    queryFn: filePath ? () => fetchDocumentFile(filePath) : skipToken,
    staleTime: FILE_URL_DURATION_MS,
  });
};
