import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

interface SendRevisionForReviewInput {
  documentId: string;
  revisionId: string;
  tenantId: string;
}

const sendRevisionForReview = async (
  input: SendRevisionForReviewInput,
  isInitialRevision: boolean
) => {
  if (isInitialRevision) {
    const { error: countError, count: revisionCount } = await supabase
      .from("document_revisions")
      .select("id", { count: "exact" })
      .eq("document_id", input.documentId)
      .eq("tenant_id", input.tenantId);

    if (countError) {
      throw countError;
    }

    if (revisionCount && revisionCount > 1) {
      throw new Error("Document has multiple revisions");
    }
  }

  const { error: revisionError } = await supabase
    .from("document_revisions")
    .update({
      status: "Pending review",
      pending_review_at: dayjs().toISOString(),
    })
    .eq("id", input.revisionId)
    .eq("tenant_id", input.tenantId);

  if (revisionError) {
    throw revisionError;
  }

  if (isInitialRevision) {
    const { error: documentError } = await supabase
      .from("documents")
      .update({ status: "In review" })
      .eq("id", input.documentId)
      .eq("tenant_id", input.tenantId);

    if (documentError) {
      throw documentError;
    }
  }
};

export const useSendRevisionForReview = (isInitialRevision: boolean) => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: SendRevisionForReviewInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return sendRevisionForReview(input, isInitialRevision);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
