import { CreateDocumentAction } from "@/components/documents/CreateDocumentAction";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { BadgeRenderer, UserRenderer } from "@/components/tables/renderers";
import TabTitle from "@/components/TabTitle";
import { useTenantContext } from "@/contexts/TenantContext";
import { Document, useDocumentsByProcess } from "@/state/queries/documents";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";
import React from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/documents"
)({
  beforeLoad: () => ({
    getTitle: () => "Documents",
  }),
  component: ProcessDocuments,
});

function ProcessDocuments() {
  const { processId } = Route.useParams();
  return (
    <>
      <TabTitle title="Documents">
        <CreateDocumentAction processId={processId} />
      </TabTitle>
      <ProcessDocumentsTable />
    </>
  );
}

const ProcessDocumentsTable: React.FC = () => {
  const { processId } = Route.useParams();
  const { data: documents, isLoading } = useDocumentsByProcess(processId);
  const { tenant } = useTenantContext();
  const colDefs: ColDef<Document>[] = [
    {
      field: "title",
      headerName: "Title",
      cellRenderer: ({ data }: { data: Document }) => (
        <Link to={`/${tenant.slug}/documents/${data.id}`}>{data.title}</Link>
      ),
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      cellRenderer: BadgeRenderer,
    },
    {
      field: "id",
      headerName: "ID",
      width: 110,
    },
    { field: "type", headerName: "Type", width: 120 },
    {
      field: "owner",
      headerName: "Owner",
      cellRenderer: UserRenderer,
      width: 180,
    },
    { field: "description", headerName: "Description", flex: 1 },
  ];
  return (
    <BaseAgGrid<Document>
      loading={isLoading}
      rowData={documents}
      columnDefs={colDefs}
    />
  );
};
