import { useTenantContext } from "@/contexts/TenantContext";
import { Document, useDocuments } from "@/state/queries/documents";
import { Process, useProcesses } from "@/state/queries/processes";
import {
  Box,
  Button,
  Dialog,
  Flex,
  Kbd,
  Text,
  TextField,
  VisuallyHidden,
} from "@radix-ui/themes";
import {
  IconArrowRightRhombus,
  IconFileText,
  IconSearch,
} from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import { groupBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import css from "./Search.module.scss";

interface BaseSearchItem {
  searchText: string;
  to: string;
  params: Record<string, string>;
}

interface ProcessItem extends BaseSearchItem {
  type: "process";
  data: Process;
}

interface DocumentItem extends BaseSearchItem {
  type: "document";
  data: Document;
}

type SearchItem = ProcessItem | DocumentItem;

function useSearchItems() {
  const { data: processes } = useProcesses();
  const { data: documents } = useDocuments();
  const { tenant } = useTenantContext();

  if (!processes || !documents) {
    return [];
  }

  const result: SearchItem[] = [];
  processes.forEach((p) => {
    result.push({
      data: p,
      type: "process",
      searchText: `${p.id} ${p.name}`,
      to: "/$tenantSlug/processes/$processId",
      params: {
        tenantSlug: tenant.slug,
        processId: p.id,
      },
    });
  });
  documents.forEach((d) => {
    result.push({
      data: d,
      type: "document",
      searchText: `${d.id} ${d.title}`,
      to: "/$tenantSlug/documents/$documentId",
      params: {
        tenantSlug: tenant.slug,
        documentId: d.id,
      },
    });
  });

  return result;
}

function Search() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchItems = useSearchItems();
  const [activeSearchItemIndex, setActiveSearchItemIndex] = useState(0);
  const navigate = useNavigate();

  const filteredSearchItems = useMemo(
    () =>
      searchItems.filter((item) =>
        item.searchText.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [searchItems, searchQuery]
  );

  const openActiveItem = useCallback(() => {
    const activeItem = filteredSearchItems[activeSearchItemIndex];
    if (activeItem) {
      navigate({ to: activeItem.to, params: activeItem.params });
      setIsSearchOpen(false);
    }
  }, [filteredSearchItems, activeSearchItemIndex, navigate, setIsSearchOpen]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isSearchOpen) {
        if ((e.metaKey || e.ctrlKey) && e.key === "k") {
          e.preventDefault();
          setIsSearchOpen(true);
          setSearchQuery("");
          setActiveSearchItemIndex(0);
        }
      } else {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setActiveSearchItemIndex((prev) =>
            Math.min(prev + 1, filteredSearchItems.length - 1)
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setActiveSearchItemIndex((prev) => Math.max(prev - 1, 0));
        } else if (e.key === "Enter") {
          e.preventDefault();
          openActiveItem();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [
    isSearchOpen,
    activeSearchItemIndex,
    filteredSearchItems,
    openActiveItem,
  ]);

  const searchResultGroups = useMemo(() => {
    const groups = groupBy(filteredSearchItems, "type");
    return Object.entries(groups).map(([type, items]) => ({
      type,
      label: type === "process" ? "Processes" : "Documents",
      items,
      startIndex: type === "process" ? 0 : groups.process?.length || 0,
    }));
  }, [filteredSearchItems]);

  return (
    <Dialog.Root open={isSearchOpen} onOpenChange={setIsSearchOpen}>
      <Dialog.Trigger>
        <SearchTrigger />
      </Dialog.Trigger>
      <Dialog.Content
        size="2"
        maxWidth="500px"
        align="start"
        style={{ marginTop: "150px" }}
      >
        <VisuallyHidden>
          <Dialog.Title>Search</Dialog.Title>
        </VisuallyHidden>
        <Flex direction="column" gap="3">
          <TextField.Root
            size="3"
            variant="surface"
            color="gray"
            placeholder="Search processes and documents..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setActiveSearchItemIndex(0);
            }}
          >
            <TextField.Slot>
              <IconSearch />
            </TextField.Slot>
          </TextField.Root>
          <Flex direction="column" gap="2">
            {searchResultGroups.map((group) => (
              <Flex key={group.type} direction="column" gap="1">
                <Text color="gray" size="2">
                  {group.label}
                </Text>
                {group.items.map((item, idx) => (
                  <SearchResult
                    key={item.searchText}
                    searchItem={item}
                    onClick={() => setIsSearchOpen(false)}
                    isActive={group.startIndex + idx === activeSearchItemIndex}
                  />
                ))}
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const SearchTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Button>
>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="surface"
      color="gray"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "var(--space-1) var(--space-2)",
        borderRadius: "var(--radius-3)",
        boxShadow: "var(--shadow-2)",
      }}
      {...props}
    >
      <Flex align="center" gap="2">
        <IconSearch />
        <Text color="gray">Search</Text>
      </Flex>
      <Flex gap="1">
        <Kbd size="1">⌘</Kbd>
        <Kbd size="1">K</Kbd>
      </Flex>
    </Button>
  );
});

interface SearchResultProps {
  searchItem: SearchItem;
  onClick: () => void;
  isActive: boolean;
}
function SearchResult({ searchItem, onClick, isActive }: SearchResultProps) {
  const icon =
    searchItem.type === "process" ? (
      <IconArrowRightRhombus />
    ) : (
      <IconFileText />
    );
  const title =
    searchItem.type === "process"
      ? searchItem.data.name
      : searchItem.data.title;
  return (
    <Box
      className={`${css.searchResult} ${isActive ? css.active : ""}`}
      onClick={onClick}
    >
      <Link to={searchItem.to} params={searchItem.params}>
        <Flex align="center" gap="2">
          {icon}
          <Text>{title}</Text>
          <Text size="2" color="gray">
            ({searchItem.data.id})
          </Text>
        </Flex>
      </Link>
    </Box>
  );
}

export default Search;
