import { routeTree } from "@/routeTree.gen";
import { Badge, Flex, TabNav } from "@radix-ui/themes";
import { Link, RoutePaths, useChildMatches } from "@tanstack/react-router";

interface MeridianTabNavProps<
  TParentRoute extends RoutePaths<typeof routeTree>,
> {
  // TODO: Figure out if we can make this typesafe
  childRouteParams: any;
  links: {
    to: Extract<RoutePaths<typeof routeTree>, `${TParentRoute}/${string}`>;
    label: string;
    badge?: string;
    icon?: React.ReactNode;
  }[];
}
const MeridianTabNav = <TParentRoute extends RoutePaths<typeof routeTree>>({
  childRouteParams,
  links,
}: MeridianTabNavProps<TParentRoute>) => {
  const childMatches = useChildMatches();
  return (
    <TabNav.Root>
      {links.map((link) => {
        const active = childMatches.find((cm) => cm.fullPath === link.to);
        return (
          <TabNav.Link key={link.to} asChild active={!!active}>
            <Link
              to={link.to as any} // TODO fix this cast to any
              params={childRouteParams}
            >
              <Flex align="center" gap="2">
                <Flex align="center" gap="1">
                  {link.icon}
                  {link.label}
                </Flex>
                {link.badge && <Badge color="gray">{link.badge}</Badge>}
              </Flex>
            </Link>
          </TabNav.Link>
        );
      })}
    </TabNav.Root>
  );
};

export default MeridianTabNav;
