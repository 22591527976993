import { useProcesses } from "@/state/queries/processes";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  Popover,
  Select,
  Separator,
  Strong,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
import { layoutPropDefs } from "@radix-ui/themes/dist/cjs/props/layout.props";
import { paddingPropDefs } from "@radix-ui/themes/dist/cjs/props/padding.props";
import { GetPropDefTypes } from "@radix-ui/themes/dist/cjs/props/prop-def";
import {
  IconArrowRightRhombus,
  IconBell,
  IconCertificate,
  IconCheckbox,
  IconClipboardCheck,
  IconFileCheck,
  IconFileText,
  IconHelp,
  IconListDetails,
  IconPlane,
} from "@tabler/icons-react";
import React, { PropsWithChildren } from "react";
import Search from "./Search";
import SidebarButton from "./SidebarButton";
import UserMenu from "./UserMenu";
import WorkspaceMenu from "./WorkspaceMenu";

const Sidebar: React.FC = () => {
  const { data: processes } = useProcesses();

  return (
    <Flex
      direction="column"
      justify="between"
      width="250px"
      height="100%"
      style={{
        backgroundColor: "var(--gray-2)",
        borderRight: "var(--line-border)",
      }}
    >
      <Flex
        py="2"
        px="4"
        align="center"
        justify="between"
        style={{ borderBottom: "var(--line-border)" }}
      >
        <WorkspaceMenu />
      </Flex>

      <Flex direction="column" flexGrow="1">
        <SidebarSection bottomBorder px="4" py="2">
          <Search />
          <Notifications />
        </SidebarSection>

        <SidebarSection
          header={{
            icon: <IconCertificate />,
            label: "Compliance",
          }}
          bottomBorder
        >
          <SidebarButton
            to="/frameworks/AS9100D"
            label="AS9100D"
            icon={<IconPlane />}
          />
          <SidebarButton
            to="/documents"
            label="Documents"
            icon={<IconFileText />}
          />
          <SidebarButton to="/tasks" label="Tasks" icon={<IconCheckbox />} />
        </SidebarSection>
        <SidebarSection
          header={{
            icon: <IconArrowRightRhombus />,
            label: "Processes",
          }}
          bottomBorder
        >
          {processes?.map((process) => (
            <SidebarButton
              key={process.id}
              to={`/processes/${process.id}`}
              label={process.name}
              icon={<IconFileCheck />}
            />
          ))}
        </SidebarSection>
        <SidebarSection
          header={{
            icon: <IconListDetails />,
            label: "Records",
          }}
          bottomBorder
        >
          <SidebarButton
            to={`/records/design-plans`}
            label="Design Plans"
            icon={<IconClipboardCheck />}
          />
        </SidebarSection>
      </Flex>

      <SidebarSection topBorder py="2">
        <Support />
        <UserMenu />
      </SidebarSection>
    </Flex>
  );
};

function Notifications() {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <SidebarButton icon={<IconBell />} label="Notifications" />
      </Popover.Trigger>
      <Popover.Content width="360px" side="right">
        <Flex direction="column" gap="2">
          <Heading size="3">Recent Notifications</Heading>
          <Text size="2">You have 3 new notifications</Text>
          <Separator size="4" />
          <Flex direction="column" gap="2">
            <Text size="2">
              <Strong>John Doe</Strong> mentioned you in a comment
            </Text>
            <Text size="2">
              <Strong>Jane Smith</Strong> assigned you a task
            </Text>
            <Text size="2">
              New supplier <Strong>Tech Innovations</Strong> added
            </Text>
          </Flex>
          <Button size="1" variant="soft">
            View all notifications
          </Button>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}

function Support() {
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <SidebarButton icon={<IconHelp />} label="Help and Support" />
      </Dialog.Trigger>
      <Dialog.Content size="2">
        <Dialog.Title>Submit a Help Request</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Describe your issue and we'll get back to you as soon as possible.
        </Dialog.Description>
        <Flex direction="column" gap="3">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Subject
            </Text>
            <TextField.Root placeholder="Issue title" size="2" />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Description
            </Text>
            <TextArea
              placeholder="Provide more details about your issue"
              size="2"
            />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Priority
            </Text>
            <Select.Root defaultValue="medium">
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="low">Low</Select.Item>
                <Select.Item value="medium">Medium</Select.Item>
                <Select.Item value="high">High</Select.Item>
              </Select.Content>
            </Select.Root>
          </label>
          <Flex gap="3" mt="4" justify="end">
            <Button size="2">Submit Request</Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

interface SidebarSectionProps
  extends PropsWithChildren,
    GetPropDefTypes<typeof paddingPropDefs & typeof layoutPropDefs> {
  header?: {
    icon: React.ReactElement;
    label: string;
  };
  topBorder?: boolean;
  bottomBorder?: boolean;
}

function SidebarSection({
  children,
  header,
  topBorder = false,
  bottomBorder = false,
  ...props
}: SidebarSectionProps) {
  let maybeHeaderElement: React.ReactNode;
  if (header) {
    maybeHeaderElement = (
      <Flex align="center" gap="1" mx="1" style={{ color: "var(--gray-10)" }}>
        {header.icon}
        <Text
          size="1"
          weight="bold"
          style={{ textTransform: "uppercase", color: "var(--gray-10)" }}
        >
          {header.label}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      {topBorder && <Separator size="4" />}
      <Flex direction="column" gap="2" p="4" {...props}>
        {maybeHeaderElement}
        <Flex direction="column" gap="3" mt="2">
          {children}
        </Flex>
      </Flex>
      {bottomBorder && <Separator size="4" />}
    </>
  );
}

export default Sidebar;
