import { useAuth } from "@/contexts/AuthContext";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

/**
 * Redirects user to login page if they are not authenticated.
 */
export function useUnauthenticatedRedirect() {
  const { isLoaded, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && !isAuthenticated) {
      navigate({
        to: "/login",
        replace: true,
      });
    }
  }, [isLoaded, isAuthenticated, navigate]);
}
