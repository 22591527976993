import { Badge, ContextMenu, Flex, Separator, Text } from "@radix-ui/themes";
import { Handle, Node, NodeProps, Position } from "@xyflow/react";
import { CSSProperties } from "react";

export type ActionNodeType = Node<
  {
    title: string;
    description?: React.ReactNode;
    icon: React.ReactNode;
    color?: string;
  },
  "action"
>;

const defaultHandleStyle = {
  zIndex: 1,
  backgroundColor: "var(--color-panel-solid)",
  border: "1px solid var(--accent-9)",
  width: "var(--space-1)",
  height: "var(--space-1)",
};

export default function ActionNode({
  data,
  selected,
  selectable,
  isConnectable,
}: NodeProps<ActionNodeType>) {
  const handleStyle: CSSProperties = {
    ...defaultHandleStyle,
    width: isConnectable ? "var(--space-1)" : "0px",
    height: isConnectable ? "var(--space-1)" : "0px",
    visibility: "hidden",
  };
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>
        <Flex
          direction="column"
          width="260px"
          style={{
            backgroundColor: "var(--color-panel-solid)",
            border: selected
              ? "1px solid var(--accent-9)"
              : "1px solid var(--gray-6)",
            boxShadow: selected
              ? "0 0 0 1px var(--accent-10), 0 0 1px 2px var(--accent-a6)"
              : "none",
            borderRadius: "var(--radius-3)",
          }}
        >
          <Flex direction="row" align="center" justify="between" p="1">
            <Flex direction="row" align="center" gap="2">
              <Badge
                variant="soft"
                color={(data.color as any) ?? "cyan"}
                style={{ padding: "2px" }}
              >
                {data.icon}
              </Badge>
              <Text size="2" weight="bold">
                {data.title}
              </Text>
            </Flex>
          </Flex>

          {data.description && (
            <>
              <Separator size="4" />

              <Flex direction="column" flexGrow="1" gap="1" p="2">
                <Text size="1" color="gray">
                  {data.description}
                </Text>
              </Flex>
            </>
          )}

          <Handle type="target" position={Position.Top} style={handleStyle} />
          <Handle
            type="source"
            position={Position.Bottom}
            id="a"
            style={handleStyle}
          />
        </Flex>
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        <ContextMenu.Item>Edit</ContextMenu.Item>
        <ContextMenu.Item>Duplicate</ContextMenu.Item>
        <ContextMenu.Separator />
        <ContextMenu.Item shortcut="⌫" color="red">
          Delete
        </ContextMenu.Item>
      </ContextMenu.Content>
    </ContextMenu.Root>
  );
}
