import { ActionButton } from "@/components/actions/ActionButton";
import NonIdealState from "@/components/NonIdealState";
import PageTitle from "@/components/PageTitle";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { getFormatterForFieldType } from "@/components/tables/formatters";
import { getRendererForFieldType } from "@/components/tables/renderers";
import {
  ActionType,
  getActionTypes,
  Record,
  RECORD_TABLE_TO_SLUG,
  RECORD_TYPES,
  RecordField,
  RecordType,
} from "@/demoData";
import { useRecords } from "@/state/queries/records";
import { Button, Flex } from "@radix-ui/themes";
import { IconFile, IconFilter, IconSortDescending } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { useMemo, useState } from "react";

export const Route = createFileRoute("/_app/$tenantSlug/records/$recordSlug")({
  beforeLoad: () => {
    return {
      getTitle: () => "Records",
    };
  },
  component: Records,
});

function Records() {
  const { recordSlug } = Route.useParams();
  const [selectedRecords, setSelectedRecords] = useState<Record[]>([]);
  const recordType = recordSlug ? RECORD_TYPES[recordSlug] : undefined;
  const { records, loading } = useRecords(recordType);

  if (!recordType) {
    return <NonIdealState icon={<IconFile />} title="Record not found" />;
  }

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title={recordType.pluralName}
        description={recordType.description}
      />
      <RecordsToolbar
        recordType={recordType}
        selectedRecords={selectedRecords}
      />
      <RecordsTable
        recordType={recordType}
        records={records}
        onSelectionChange={setSelectedRecords}
        loading={loading}
      />
    </Flex>
  );
}

const RecordsToolbar: React.FC<{
  recordType: RecordType;
  selectedRecords: Record[];
}> = ({ recordType, selectedRecords }) => {
  const createActions = getActionTypes(recordType.slug, ["create"]);
  const modifyActions = getActionTypes(recordType.slug, ["update", "delete"]);
  return (
    <Flex justify="between" align="center" py="2">
      <Flex gap="2">
        <Button variant="outline" color="gray">
          <IconSortDescending />
          Sort
        </Button>
        <Button variant="outline" color="gray">
          <IconFilter />
          Filter
        </Button>
      </Flex>
      <RecordActions
        reload={() => {}}
        selectedRecords={selectedRecords}
        createActions={createActions}
        modifyActions={modifyActions}
      />
    </Flex>
  );
};

const RecordActions: React.FC<{
  reload: () => void;
  selectedRecords: Record[];
  createActions: ActionType[];
  modifyActions: ActionType[];
}> = ({ reload, selectedRecords, createActions, modifyActions }) => {
  let actions = [];
  if (selectedRecords.length > 0) {
    actions.push(
      ...modifyActions.map((action) => (
        <ActionButton
          key={action.id}
          action={action}
          selectedRecords={selectedRecords}
        />
      ))
    );
  } else {
    actions.push(
      ...createActions.map((action) => (
        <ActionButton
          key={action.id}
          action={action}
          selectedRecords={selectedRecords}
        />
      ))
    );
  }
  return <Flex gap="2">{actions}</Flex>;
};

const fieldToColDef = (field: RecordField): ColDef<Record> => {
  return {
    field: field.apiName as keyof Record,
    headerName: field.name,
    valueFormatter: getFormatterForFieldType(field.type),
    cellRenderer: getRendererForFieldType(field.type),
  };
};

const getColDefs = (
  tenantSlug: string,
  recordType: RecordType
): ColDef<Record>[] => {
  const recordTypeSlug = RECORD_TABLE_TO_SLUG[recordType.databaseTable];
  const colDefs: ColDef<Record>[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      cellRenderer: (props: ICellRendererParams) => {
        return (
          <Link
            to="/$tenantSlug/records/$recordSlug/$recordId"
            params={{
              tenantSlug,
              recordSlug: recordTypeSlug,
              recordId: props.data.id,
            }}
          >
            {props.value}
          </Link>
        );
      },
      cellRendererParams: {
        databaseTable: recordType.databaseTable,
      },
    },
  ];
  return [
    ...colDefs,
    ...recordType.fields
      .filter((field) => field.apiName !== "name")
      .map(fieldToColDef),
  ];
};

const RecordsTable: React.FC<{
  recordType: RecordType;
  records: Record[];
  loading: boolean;
  onSelectionChange: (selectedRows: Record[]) => void;
}> = ({ recordType, records, loading, onSelectionChange }) => {
  const { tenantSlug } = Route.useParams();
  const handleRowSelected = (event: RowSelectedEvent<Record>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows);
  };

  const colDefs = useMemo(
    () => getColDefs(tenantSlug, recordType),
    [tenantSlug, recordType]
  );

  return (
    <BaseAgGrid<Record>
      loading={loading}
      rowData={records}
      columnDefs={colDefs}
      selection={{
        mode: "singleRow",
      }}
      onRowSelected={handleRowSelected}
    />
  );
};

export default Records;
