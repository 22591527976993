import EmptyPage from "@/components/EmptyPage";
import { IconCheckbox } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/tasks")({
  beforeLoad: () => {
    return {
      getTitle: () => "Tasks",
    };
  },
  component: () => <EmptyPage icon={<IconCheckbox />} title="Tasks" />,
});
