import EmptyPage from "@/components/EmptyPage";
import { useIsInitialLoading } from "@/hooks/useIsInitialLoading";
import { useTenantRedirect } from "@/hooks/useTenantRedirect";
import { useTenants } from "@/state/queries/tenants";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  TextField,
} from "@radix-ui/themes";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";
import { z } from "zod";
import { supabase } from "../supabaseClient";

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  component: Login,
});

function Login() {
  const redirect = Route.useSearch({
    select: (search) => search.redirect,
  });
  useTenantRedirect(redirect);

  const isInitialLoading = useIsInitialLoading();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { refetch: refetchTenants } = useTenants();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    await refetchTenants();
    navigate({ to: redirect || "/" });
  };

  if (isInitialLoading) {
    return <EmptyPage icon={<Spinner />} />;
  }

  return (
    <Flex height="100vh">
      <Box
        width="40vw"
        maxWidth="500px"
        p="4"
        style={{
          background:
            "url(https://images.unsplash.com/photo-1517976487492-5750f3195933?q=80&w=1100)",
          backgroundColor: "var(--gray-12)",
          backgroundSize: "cover",
          backgroundPosition: "left 20% center",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
      <Flex flexGrow="1" justify="center" align="center">
        <Box
          p="6"
          width="400px"
          style={{
            background: "white",
            borderRadius: "var(--radius-3)",
            boxShadow: "var(--shadow-3)",
          }}
        >
          <Heading size="6" mb="4" align="center">
            Log in to Meridian
          </Heading>
          <form onSubmit={handleLogin}>
            <Flex direction="column" gap="3">
              <TextField.Root
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField.Root
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button type="submit" disabled={loading}>
                {loading ? "Loading..." : "Log in"}
              </Button>
              {error && (
                <Text color="red" align="center">
                  {error}
                </Text>
              )}
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
