import { Badge, ContextMenu, Flex, Text } from "@radix-ui/themes";
import { Handle, Node, NodeProps, Position } from "@xyflow/react";
import { CSSProperties } from "react";

export type StatusNodeType = Node<
  {
    title: string;
    icon: React.ReactNode;
    color?: string;
  },
  "status"
>;

const defaultHandleStyle = {
  zIndex: 1,
  backgroundColor: "var(--color-panel-solid)",
  border: "1px solid var(--accent-9)",
  width: "var(--space-1)",
  height: "var(--space-1)",
};

export default function StatusNode({
  data,
  selected,
  selectable,
  isConnectable,
}: NodeProps<StatusNodeType>) {
  const handleStyle: CSSProperties = {
    ...defaultHandleStyle,
    width: isConnectable ? "var(--space-1)" : "0px",
    height: isConnectable ? "var(--space-1)" : "0px",
    visibility: "hidden",
  };
  const color = data.color ?? "cyan";
  const backgroundColor = `var(--${color}-9)`;
  const borderColor = `var(--${color}-9)`;
  const shadowColor = `var(--${color}-a6)`;
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>
        <Flex
          direction="column"
          width="200px"
          p="1"
          style={{
            color: "var(--accent-contrast)",
            backgroundColor: backgroundColor,
            border: `1px solid ${shadowColor}`,
            boxShadow: selected
              ? `0 0 0 1px ${borderColor}, 0 0 1px 2px ${shadowColor}`
              : "none",
            borderRadius: "var(--radius-3)",
          }}
        >
          <Flex direction="row" align="center" justify="between" p="1">
            <Flex direction="row" align="center" gap="2">
              <Badge
                variant="soft"
                style={{ padding: "2px", color: "var(--accent-contrast)" }}
              >
                {data.icon}
              </Badge>
              <Text size="2" weight="bold">
                {data.title}
              </Text>
            </Flex>
          </Flex>

          <Handle type="target" position={Position.Top} style={handleStyle} />
          <Handle
            type="source"
            position={Position.Bottom}
            id="a"
            style={handleStyle}
          />
        </Flex>
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        <ContextMenu.Item>Edit</ContextMenu.Item>
        <ContextMenu.Item>Duplicate</ContextMenu.Item>
        <ContextMenu.Separator />
        <ContextMenu.Item shortcut="⌫" color="red">
          Delete
        </ContextMenu.Item>
      </ContextMenu.Content>
    </ContextMenu.Root>
  );
}
