import AppToolbar from "@/components/AppToolbar";
import Sidebar from "@/components/sidebar/Sidebar";
import { Container, Flex } from "@radix-ui/themes";

export const AppLayout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Flex direction="row" height="100vh">
      <Sidebar />
      <Flex direction="column" flexGrow="1" overflowY="scroll">
        <AppToolbar />
        <Container
          p="5"
          align={{ md: "left", xl: "center" }}
          size={{ md: "3", lg: "4" }}
        >
          {props.children}
        </Container>
      </Flex>
    </Flex>
  );
};
