import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface EditProcessInput {
  id: string;
  name: string;
  description?: string;
  owner: string;
}

const editProcess = async (input: EditProcessInput, tenantId: string) => {
  const { error } = await supabase
    .from("processes")
    .update({
      name: input.name,
      description: input.description,
      owned_by: input.owner,
    })
    .eq("id", input.id)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useEditProcess = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: EditProcessInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return editProcess(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
