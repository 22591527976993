import { Flex } from "@radix-ui/themes";
import { PropsWithChildren } from "react";

export const FormFields: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex direction="column" gap="2">
      {children}
    </Flex>
  );
};
