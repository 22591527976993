import EmptyPage from "@/components/EmptyPage";
import NotFoundPage from "@/components/NotFoundPage";
import { AuthContext, useAuth } from "@/contexts/AuthContext";
import { useTenants } from "@/state/queries/tenants";
import { useUser } from "@/state/queries/user";
import { Spinner } from "@radix-ui/themes";
import { IconAlertCircle } from "@tabler/icons-react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "../routeTree.gen";

export interface RouterContext {
  auth: AuthContext;
  queryClient: QueryClient;
  user: ReturnType<typeof useUser>;
  tenants: ReturnType<typeof useTenants>;
}

export const defaultRouterContext: RouterContext = {
  auth: undefined!,
  queryClient: undefined!,
  user: undefined!,
  tenants: undefined!,
};

export const router = createRouter({
  routeTree,
  context: defaultRouterContext,
  defaultErrorComponent: () => (
    <EmptyPage
      icon={<IconAlertCircle />}
      title="Error"
      description="An unexpected error occurred"
    />
  ),
  defaultNotFoundComponent: () => <NotFoundPage />,
  defaultPendingComponent: () => <EmptyPage icon={<Spinner />} />,
});

// Register generated router typings
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export const Router = () => {
  const auth = useAuth();
  const userQuery = useUser();
  const tenantQuery = useTenants();
  const queryClient = useQueryClient();

  return (
    <RouterProvider
      router={router}
      context={{
        auth,
        queryClient,
        user: userQuery,
        tenants: tenantQuery,
      }}
    />
  );
};
