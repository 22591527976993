import { BaseFileUpload, BaseFileUploadProps } from "./BaseFileUpload";
import { BaseSwitch, BaseSwitchProps } from "./BaseSwitch";
import { BaseTextArea, BaseTextAreaProps } from "./BaseTextArea";
import { BaseTextField, BaseTextFieldProps } from "./BaseTextField";
import { CancelButton, CancelButtonProps } from "./CancelButton";
import { FormError } from "./FormError";
import { FormField } from "./FormField";
import { FormFields } from "./FormFields";
import { FormRoot, FormRootProps } from "./FormRoot";
import { BaseSelect, BaseSelectProps } from "./select/BaseSelect";
import { ProcessSelect } from "./select/ProcessSelect";
import { UserSelect, UserSelectProps } from "./select/UserSelect";
import { SubmitButton, SubmitButtonProps } from "./SubmitButton";
import { FormFieldProps } from "./types";

const Form = {
  Root: FormRoot,
  Fields: FormFields,
  Field: FormField,
  TextField: BaseTextField,
  TextArea: BaseTextArea,
  Select: BaseSelect,
  UserSelect: UserSelect,
  ProcessSelect: ProcessSelect,
  FileUpload: BaseFileUpload,
  Switch: BaseSwitch,
  SubmitButton: SubmitButton,
  CancelButton: CancelButton,
  Error: FormError,
};
export type {
  CancelButtonProps,
  FormFieldProps as FieldProps,
  BaseFileUploadProps as FileUploadProps,
  FormRootProps as RootProps,
  BaseSelectProps as SelectProps,
  SubmitButtonProps,
  BaseSwitchProps as SwitchProps,
  BaseTextAreaProps as TextAreaProps,
  BaseTextFieldProps as TextFieldProps,
  UserSelectProps,
};

export default Form;
