import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateDocumentInputs {
  file: File | null;
  name: string;
  description: string;
  type: string;
  owner: string;
  id: string;
  processId: string;
}

// TODO Add cleanup if mutation fails partway
const createDocument = async (
  input: CreateDocumentInputs,
  tenantId: string
) => {
  if (!input.file) {
    throw new Error("File is required");
  }

  // Create document
  const { error: documentError } = await supabase.from("documents").insert({
    tenant_id: tenantId,
    id: input.id,
    title: input.name,
    description: input.description,
    owned_by: input.owner,
    document_type_id: input.type,
    process_id: input.processId,
  });

  if (documentError) {
    throw documentError;
  }

  // Create placeholder document revision
  const { data: revisionData, error: revisionError } = await supabase
    .from("document_revisions")
    .insert({
      tenant_id: tenantId,
      document_id: input.id,
      file_path: "placeholder",
      storage_type: "file_storage",
      content_type: input.file.type,
      original_filename: input.file.name,
    })
    .select("id")
    .single();

  if (revisionError) {
    throw revisionError;
  }

  if (!revisionData) {
    throw new Error("Failed to create document revision");
  }

  // Upload file to supabase storage under revision ID
  const filePath = `${tenantId}/${revisionData.id}/${input.file.name}`;
  const { error: fileError } = await supabase.storage
    .from("documents")
    .upload(filePath, input.file);

  if (fileError) {
    throw fileError;
  }

  // Update document revision with file path and document ID
  const { error: updateError } = await supabase
    .from("document_revisions")
    .update({ file_path: filePath, document_id: input.id })
    .eq("id", revisionData.id);

  if (updateError) {
    throw updateError;
  }
};

export const useCreateDocument = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: CreateDocumentInputs) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createDocument(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
