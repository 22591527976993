import { AddComplianceMappingAction } from "@/components/processes/AddComplianceMappingAction";
import { RemoveComplianceMappingAction } from "@/components/processes/RemoveComplianceMappingAction";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import TabTitle from "@/components/TabTitle";
import {
  ProcessComplianceMapping,
  useProcess,
} from "@/state/queries/processes";
import { Flex } from "@radix-ui/themes";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/compliance"
)({
  beforeLoad: () => ({
    getTitle: () => "Compliance",
  }),
  component: CompliancePage,
});

function CompliancePage() {
  const { processId } = Route.useParams();
  const { data: process, isLoading } = useProcess(processId);
  const [selectedMapping, setSelectedMapping] = useState<
    { frameworkId: string; clause: string } | undefined
  >(undefined);

  return (
    <>
      <TabTitle title="Compliance">
        {selectedMapping && (
          <RemoveComplianceMappingAction
            processId={processId}
            frameworkId={selectedMapping.frameworkId}
            clause={selectedMapping.clause}
          />
        )}
        {process && !selectedMapping && (
          <AddComplianceMappingAction process={process} />
        )}
      </TabTitle>
      <ComplianceMappingTable
        isLoading={isLoading}
        mappings={process?.complianceMappings ?? []}
        onSelectMapping={setSelectedMapping}
      />
    </>
  );
}

interface ProcessComplianceProps {
  isLoading: boolean;
  mappings: ProcessComplianceMapping[];
  onSelectMapping: (mapping: { frameworkId: string; clause: string }) => void;
}

const ComplianceMappingTable: React.FC<ProcessComplianceProps> = ({
  isLoading,
  mappings,
  onSelectMapping,
}) => {
  const { tenantSlug } = Route.useParams();
  const colDefs: ColDef<ProcessComplianceMapping>[] = [
    {
      field: "frameworkId",
      headerName: "Framework",
      width: 120,
    },
    {
      field: "frameworkId",
      headerName: "Requirement",
      cellRenderer: (props: ICellRendererParams) => {
        return (
          <Link
            to="/$tenantSlug/frameworks/$frameworkId"
            params={{ tenantSlug, frameworkId: props.data.frameworkId }}
            search={{ filter: props.data.clause }}
          >
            Clause {props.data.clause}
          </Link>
        );
      },
      width: 220,
    },
    {
      field: "title",
      flex: 1,
    },
  ];
  return (
    <Flex direction="column" gap="3">
      <BaseAgGrid<ProcessComplianceMapping>
        loading={isLoading}
        rowData={mappings}
        columnDefs={colDefs}
        selection={{
          mode: "singleRow",
        }}
        suppressCellFocus
        onSelectionChanged={(event) => {
          const mapping = event.api.getSelectedRows()[0];
          onSelectMapping(mapping);
        }}
      />
    </Flex>
  );
};
