import { IconHelpCircle } from "@tabler/icons-react";
import React from "react";
import EmptyPage, { EmptyPageProps } from "./EmptyPage";

interface NotFoundPageProps extends EmptyPageProps {}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  icon = <IconHelpCircle />,
  title = "Not found",
  description,
  action,
}) => {
  return (
    <EmptyPage
      icon={icon}
      title={title}
      description={description}
      action={action}
    />
  );
};

export default NotFoundPage;
