import {
  Box,
  Button,
  Callout,
  Dialog,
  Flex,
  Heading,
  Inset,
  Popover,
  ScrollArea,
  Skeleton,
  Spinner,
  Strong,
  Text,
} from "@radix-ui/themes";
import {
  IconAlertTriangle,
  IconCertificate,
  IconCheck,
  IconSparkles,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

interface NonConformityCalloutProps {
  isLoading: boolean;
}

const NonConformityCallout: React.FC<NonConformityCalloutProps> = ({
  isLoading,
}) => {
  const textContent = isLoading ? (
    <Skeleton width="100%" minWidth="100%" height="100%" minHeight="100%">
      <Text size="2">
        Loading AI-generated feedback on proposed process changes...
      </Text>
      <Text size="2">Results generated by AI. Results may vary.</Text>
    </Skeleton>
  ) : (
    <>
      <Text size="2">
        This process change may cause non-compliance with AS9100 requirement
        8.3.3.{" "}
      </Text>
      <Popover.Root>
        <Popover.Trigger>
          <Text
            size="2"
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            View details
          </Text>
        </Popover.Trigger>
        <Popover.Content>
          <Inset>
            <Flex direction="column">
              <Flex
                align="center"
                p="2"
                gap="1"
                style={{
                  borderBottom: "var(--line-border)",
                }}
              >
                <IconCertificate />
                <Heading size="2">Compliance Guide</Heading>
              </Flex>
              <Box p="2">
                <Text size="2">
                  AS9100 requirement 8.3.3 (Design and Development Inputs)
                  states the following:{" "}
                  <Strong>
                    The organization shall determine the requirements essential
                    for the specific types of products and services to be
                    designed and developed. The organization shall consider:
                    functional and performance requirements; information derived
                    from previous similar design and development activities;
                    statutory and regulatory requirements ...
                  </Strong>
                </Text>
              </Box>
            </Flex>
          </Inset>
        </Popover.Content>
      </Popover.Root>
      .
    </>
  );
  return (
    <Callout.Root
      variant="surface"
      color={isLoading ? "gray" : "orange"}
      mb="4"
    >
      <Callout.Icon>
        {isLoading ? <Spinner size="1" /> : <IconAlertTriangle />}
      </Callout.Icon>
      <Box className="rt-CalloutText">
        <Flex align="center" justify="between" mb="2">
          <Heading size="3">
            {isLoading
              ? "Analyzing changes"
              : "Potential non-conformity detected"}
          </Heading>
          <Flex align="center" gap="1">
            <IconSparkles />
            <Text size="1">Generated using AI</Text>
          </Flex>
        </Flex>
        {textContent}
      </Box>
    </Callout.Root>
  );
};

const SaveProcessDialog: React.FC<{ onSave: () => void }> = ({ onSave }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button variant="soft" color="green">
          <IconCheck />
          Save
        </Button>
      </Dialog.Trigger>

      <Dialog.Content size="2">
        <Dialog.Title>Save Process Changes</Dialog.Title>
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="2">
            <Heading size="2">Change Summary</Heading>
            <Text size="2">
              The following resources associated with this process have been
              changed.
            </Text>
            <ScrollArea scrollbars="vertical" style={{ maxHeight: "100px" }}>
              <Flex direction="column" gap="2">
                <Button variant="outline" color="gray" size="2" asChild>
                  <Flex justify="start" gap="2">
                    Workflow status removed: Draft
                  </Flex>
                </Button>
                <Button variant="outline" color="gray" size="2" asChild>
                  <Flex justify="start" gap="2">
                    Workflow step removed: Add requirements matrix
                  </Flex>
                </Button>
              </Flex>
            </ScrollArea>
          </Flex>
          <Flex direction="column" gap="2">
            <Heading size="2">AI Review</Heading>
            <NonConformityCallout isLoading={isLoading} />
          </Flex>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Button color="green" onClick={onSave} disabled={isLoading}>
            Save
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default SaveProcessDialog;
