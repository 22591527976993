import { Button, Container, Flex } from "@radix-ui/themes";
import React from "react";
import css from "./List.module.scss";
interface ListRootProps {
  size?: "1" | "2" | "3";
  children: React.ReactNode;
}

function ListRoot({ size = "2", children }: ListRootProps) {
  const marginSize = size === "1" ? "1" : size === "3" ? "3" : "2";

  return (
    <Container>
      <Flex
        direction="column"
        m={marginSize}
        style={{
          border: "var(--line-border)",
          borderRadius: "var(--radius-3)",
        }}
      >
        {children}
      </Flex>
    </Container>
  );
}

interface ListItemProps {
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}
function ListItem({ onClick, disabled, children }: ListItemProps) {
  return (
    <Button
      className={css["list-item"]}
      variant="ghost"
      color="gray"
      size="2"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

const List = {
  Item: ListItem,
  Root: ListRoot,
};
export type { ListItemProps as ItemProps, ListRootProps as RootProps };

export default List;
