import Form from "@/components/forms";
import { ActionFormFieldType, ActionType, Record } from "@/demoData";
import {
  Button,
  ButtonProps,
  Dialog,
  Flex,
  Select,
  Text,
} from "@radix-ui/themes";
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons-react";
import { FormApi, useForm } from "@tanstack/react-form";

export const ActionButton: React.FC<{
  action: ActionType;
  selectedRecords: Record[];
}> = ({ action, selectedRecords }) => {
  const operation = action.primaryOperation;
  const permission = action.permission;

  const form = useForm<any, any>({
    defaultValues: {},
    onSubmit: async ({ value }) => {
      console.log(value);
    },
  });

  if (permission && !permission({ record: selectedRecords[0] })) {
    return null;
  }

  let buttonProps: ButtonProps = {};
  let icon: React.ReactNode | undefined;
  if (operation === "create") {
    buttonProps = {};
    icon = <IconPlus />;
  } else if (operation === "update") {
    buttonProps = {
      variant: "soft",
    };
    icon = <IconPencil />;
  } else if (operation === "delete") {
    buttonProps = {
      variant: "soft",
      color: "red",
    };
    icon = <IconTrash />;
  }

  const formFields = action.form.fields.map((field) => (
    <ActionFormField form={form} key={field.apiName} field={field} />
  ));

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button {...buttonProps}>
          {icon}
          {action.title}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>{action.title}</Dialog.Title>
        <Dialog.Description mb="2">
          <Text color="gray" size="2">
            {action.description}
          </Text>
        </Dialog.Description>
        <Form.Root reactForm={form}>
          <Form.Fields>{formFields}</Form.Fields>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <>
                <Flex gap="3" justify="end">
                  <Form.CancelButton form={form} />
                  <Form.SubmitButton
                    canSubmit={canSubmit}
                    isSubmitting={isSubmitting}
                  >
                    Submit
                  </Form.SubmitButton>
                </Flex>
              </>
            )}
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const ActionFormField: React.FC<{
  form: FormApi<any, any>;
  field: ActionFormFieldType;
}> = ({ form, field }) => {
  if (field.type === "record") {
    return null;
  }

  const reactFormField = { form, name: field.apiName };
  const fieldProps = {
    label: field.name,
    required: field.required,
  };

  if (field.type === "string") {
    return (
      <Form.TextField
        reactFormField={reactFormField}
        fieldProps={fieldProps}
        placeholder="Enter value"
      />
    );
  } else if (field.type === "longText") {
    return (
      <Form.TextArea
        reactFormField={reactFormField}
        fieldProps={fieldProps}
        placeholder="Enter value"
      />
    );
  } else if (field.type === "number") {
    return (
      <Form.TextField
        reactFormField={reactFormField}
        fieldProps={fieldProps}
        type="number"
        placeholder="123"
      />
    );
  } else if (field.type === "boolean") {
    return (
      <Form.Switch reactFormField={reactFormField} fieldProps={fieldProps} />
    );
  } else if (field.type === "enum") {
    // TODO Add actual options
    return (
      <Form.Select reactFormField={reactFormField} fieldProps={fieldProps}>
        <Select.Content>
          <Select.Item value="optionA">Electrical</Select.Item>
          <Select.Item value="optionB">Mechanical</Select.Item>
          <Select.Item value="optionC">Electro-mechanical</Select.Item>
        </Select.Content>
      </Form.Select>
    );
  } else if (field.type === "file[]") {
    return (
      <Form.FileUpload
        reactFormField={reactFormField}
        fieldProps={fieldProps}
      />
    );
  }
};
