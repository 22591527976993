import {
  IconCalendar,
  IconCheckbox,
  IconClipboardCheck,
  IconClock,
  IconFile,
  IconForms,
  IconList,
  IconNumber123,
} from "@tabler/icons-react";
import { RecordsDatabase } from "./database/types";

type RecordTables = keyof RecordsDatabase["records"]["Tables"];

export type DesignPlan =
  RecordsDatabase["records"]["Tables"]["design_plans"]["Row"];
export type Record = DesignPlan;

export interface RecordField {
  apiName: string;
  name: string;
  description: string;
  type:
    | "string"
    | "longText"
    | "number"
    | "date"
    | "timestamp"
    | "boolean"
    | "file[]"
    | "enum";
  // TODO: Field structure should include type, formatting, validation, and other details (e.g. enum values)
}

export const recordFieldTypeToIcon = {
  string: <IconForms />,
  longText: <IconForms />,
  number: <IconNumber123 />,
  date: <IconCalendar />,
  timestamp: <IconClock />,
  boolean: <IconCheckbox />,
  "file[]": <IconFile />,
  enum: <IconList />,
};

export const recordFieldTypeToLabel = {
  string: "Text",
  longText: "Long text",
  number: "Number",
  date: "Date",
  timestamp: "Timestamp",
  boolean: "Boolean",
  "file[]": "Files",
  enum: "Option list",
};

export type RecordTypeSource = "Managed" | "External";

export interface RecordType {
  id: string;
  slug: string;
  icon: React.ReactElement;
  databaseTable: RecordTables;
  source: RecordTypeSource;
  name: string;
  pluralName: string;
  shortName: string;
  description: string;
  fields: RecordField[];
}

export const RECORD_TYPES: { [slug: string]: RecordType } = {
  "design-plans": {
    id: "1",
    slug: "design-plans",
    icon: <IconClipboardCheck />,
    databaseTable: "design_plans",
    source: "Managed",
    name: "Design Plan",
    pluralName: "Design Plans",
    shortName: "Design Plans",
    description: "Records of plans to design new products.",
    fields: [
      {
        apiName: "name",
        name: "Name",
        description: "The name of the design project.",
        type: "string",
      },
      {
        apiName: "description",
        name: "Description",
        description: "A description of the design project.",
        type: "longText",
      },
      {
        apiName: "status",
        name: "Status",
        description: "The current status of the design plan.",
        type: "enum",
      },
      {
        apiName: "requirements",
        name: "Requirements Matrix",
        description: "The requirements matrix for the design plan.",
        type: "file[]",
      },
      {
        apiName: "created_at",
        name: "Created at",
        description: "The date the record was created.",
        type: "timestamp",
      },
      {
        apiName: "updated_at",
        name: "Updated at",
        description: "The date the record was last updated.",
        type: "timestamp",
      },
      {
        apiName: "requirements_added_at",
        name: "Requirements added at",
        description: "The date the requirements were added.",
        type: "timestamp",
      },
      {
        apiName: "approved_at",
        name: "Approved at",
        description: "The date the design plan was approved.",
        type: "timestamp",
      },
    ],
  },
};

export const RECORD_TABLE_TO_SLUG = Object.fromEntries(
  Object.entries(RECORD_TYPES).map(([slug, recordType]) => [
    recordType.databaseTable,
    slug,
  ])
);

export enum DesignPlanStatus {
  Draft = "Draft",
  PendingReview = "Pending review",
  Approved = "Approved",
}

export type ActionOperation = "create" | "update" | "delete";

export interface PermissionContext {
  record: Record;
}

export interface ActionPermissions {
  canView?: (context: PermissionContext) => boolean;
  canApply?: (context: PermissionContext) => boolean;
}

export interface FormConfig {
  fields: ActionFormFieldType[];
  canSubmit?: (context: FormLogicContext) => boolean;
}

interface FormLogicContext {
  fieldValues: { [apiName: string]: any };
}

interface EffectLogicContext extends FormLogicContext {
  // TODO type effect outputs better. Probably contains...
  //   - id: ID of created/updated/deleted record
  //   - fieldValues: latest values of created/updated record
  effectOutputs: { [effectId: string]: any };
}

export interface ActionFormFieldType {
  apiName: string;
  name: string;
  type: RecordField["type"] | "record";
  displayConfig?: any; // TODO type this
  visible?: boolean;
  required?: boolean;
  // TODO support prefilling fields from record table view
  prefill?: (context: FormLogicContext) => any;
  validator?: (value?: any) => boolean;
}

interface LiteralRecordFieldValue {
  type: "literalRecordFieldValue";
  value: any;
}

interface FormToRecordField {
  type: "formToRecordField";
  formFieldApiName: string;
}

interface LogicToRecordField {
  type: "logicToRecordField";
  logic: (context: EffectLogicContext) => any;
}

type RecordFieldMapping =
  | LiteralRecordFieldValue
  | FormToRecordField
  | LogicToRecordField;

interface ModifyRecordEffect {
  type: "modifyRecord";
  operation: ActionOperation;
  recordTypeSlug: string;

  // Only present for Update and Delete operations
  recordId?: RecordFieldMapping;
  // Only present for Create and Update operations
  recordFieldValueMapping: { [recordFieldApiName: string]: RecordFieldMapping };
}

interface CreateTaskEffect {
  type: "createTask";
  title: string;
  description: string;
  assignee: string;
}

interface SendNotificationEffect {
  type: "sendNotification";
  title: string;
  description: string;
}

type ActionEffect = { id: string } & (
  | ModifyRecordEffect
  | CreateTaskEffect
  | SendNotificationEffect
);

export interface ActionType {
  id: string;
  recordTypeSlug: string;
  primaryOperation: ActionOperation;
  title: string;
  description?: string;
  form: FormConfig;
  effects: ActionEffect[];
  permission?: (context: PermissionContext) => boolean;
}

export interface Status {
  value: DesignPlanStatus;
  color: string;
}

export interface Workflow {
  id: string;
  primaryRecordTypeSlug: string;
  title: string;
  description: string;
  firstNodeId: string;
  nodes: { [id: string]: WorkflowNode };
}

interface WorkflowNode {
  id: string;
  type: "status" | "action";
  status?: Status;
  action?: ActionType;
  nextNodeIds: string[];
}

export const ACTION_TYPES: { [id: string]: ActionType } = {
  "1": {
    id: "1",
    recordTypeSlug: "design-plans",
    primaryOperation: "create",
    title: "New design plan",
    description: "Initiate creating a new design plan",
    form: {
      fields: [
        {
          apiName: "name",
          name: "Name",
          type: "string",
          required: true,
        },
        {
          apiName: "description",
          name: "Description",
          type: "longText",
          required: true,
        },
      ],
    },
    effects: [
      // Create design plan
      {
        id: "1",
        type: "modifyRecord",
        operation: "create",
        recordTypeSlug: "design-plans",
        recordFieldValueMapping: {
          name: {
            type: "formToRecordField",
            formFieldApiName: "name",
          },
          description: {
            type: "formToRecordField",
            formFieldApiName: "description",
          },
        },
      },
    ],
  },
  "2": {
    id: "2",
    recordTypeSlug: "design-plans",
    primaryOperation: "update",
    title: "Edit design plan",
    permission: (context: PermissionContext) => {
      return context.record.status === DesignPlanStatus.Draft;
    },
    form: {
      fields: [
        {
          apiName: "name",
          name: "Name",
          type: "string",
          required: true,
        },
        {
          apiName: "description",
          name: "Description",
          type: "longText",
          required: true,
        },
      ],
    },
    effects: [],
  },
  "3": {
    id: "3",
    recordTypeSlug: "design-plans",
    primaryOperation: "update",
    title: "Add requirements matrix",
    description:
      "Add a requirements matrix to the design plan to capture customer requirements",
    permission: (context: PermissionContext) => {
      return context.record.status === DesignPlanStatus.Draft;
    },
    form: {
      fields: [
        {
          apiName: "design_plan_id",
          name: "Design Plan",
          type: "record",
          required: true,
        },
        {
          apiName: "requirements",
          name: "Requirements Matrix",
          type: "file[]",
          required: true,
        },
      ],
      canSubmit: (ctx) => ctx.fieldValues["requirements"].length > 0,
    },
    effects: [
      // Update design plan
      {
        id: "1",
        type: "modifyRecord",
        operation: "update",
        recordTypeSlug: "design-plans",
        recordId: {
          type: "formToRecordField",
          formFieldApiName: "design_plan_id",
        },
        recordFieldValueMapping: {
          // Will be automatically added by Workflow builder
          status: {
            type: "literalRecordFieldValue",
            value: "Pending review",
          },
        },
      },
      // Create Task for reviewer
      {
        id: "2",
        type: "createTask",
        title: "Review design plan",
        description: "A new design plan is ready for your review.",
        assignee: "...",
      },
    ],
  },
  "4": {
    id: "4",
    recordTypeSlug: "design-plans",
    primaryOperation: "update",
    title: "Approve design plan",
    description: "Approve the design plan to release it for development",
    permission: (context: PermissionContext) => {
      return context.record.status === DesignPlanStatus.PendingReview;
    },
    form: {
      fields: [
        {
          apiName: "design_plan_id",
          name: "Design Plan",
          type: "record",
          visible: false,
          required: true,
        },
        {
          apiName: "confirm",
          name: "Confirm?",
          type: "boolean",
          required: true,
        },
      ],
      canSubmit: (ctx) => ctx.fieldValues["confirm"],
    },
    effects: [
      {
        id: "1",
        type: "modifyRecord",
        operation: "update",
        recordTypeSlug: "design-plans",
        recordId: {
          type: "formToRecordField",
          formFieldApiName: "design_plan_id",
        },
        recordFieldValueMapping: {
          // Will be automatically added by Workflow builder
          status: {
            type: "literalRecordFieldValue",
            value: "Approved",
          },
        },
      },
    ],
  },
};

export const DEMO_WORKFLOW: Workflow = {
  id: "1",
  primaryRecordTypeSlug: "design-plans",
  title: "Design Plan Workflow",
  description: "Workflow for design plans",
  firstNodeId: "1",
  nodes: {
    "1": {
      id: "1",
      type: "action",
      // New design plan
      action: ACTION_TYPES["1"],
      nextNodeIds: ["2"],
    },
    "2": {
      id: "2",
      type: "status",
      status: {
        value: DesignPlanStatus.Draft,
        color: "iris",
      },
      nextNodeIds: ["3", "4"],
    },
    "3": {
      id: "3",
      type: "action",
      // Edit design plan
      action: ACTION_TYPES["2"],
      nextNodeIds: ["2"],
    },
    "4": {
      id: "3",
      type: "action",
      // Send for review
      action: ACTION_TYPES["3"],
      nextNodeIds: ["5"],
    },
    "5": {
      id: "4",
      type: "status",
      status: {
        value: DesignPlanStatus.PendingReview,
        color: "yellow",
      },
      nextNodeIds: ["6"],
    },
    "6": {
      id: "5",
      type: "action",
      // Approve design plan
      action: ACTION_TYPES["4"],
      nextNodeIds: ["7"],
    },
    "7": {
      id: "6",
      type: "status",
      status: {
        value: DesignPlanStatus.Approved,
        color: "green",
      },
      nextNodeIds: [],
    },
  },
};

export const getActionTypes = (
  recordTypeSlug: string,
  operations?: ActionOperation[]
): ActionType[] => {
  return Object.values(ACTION_TYPES).filter(
    (actionType) =>
      actionType.recordTypeSlug === recordTypeSlug &&
      (operations === undefined ||
        operations.includes(actionType.primaryOperation))
  );
};
