import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface EditDocumentInputs {
  id: string;
  name: string;
  description: string;
  owner: string;
  processId: string;
  file: File | undefined;
}

const editDocument = async (input: EditDocumentInputs, tenantId: string) => {
  if (input.file) {
    // Get file path from document revision
    const { data: revisionData, error: revisionError } = await supabase
      .from("document_revisions")
      .select("id, file_path")
      .eq("document_id", input.id)
      .single();

    if (revisionError || !revisionData) {
      throw revisionError;
    }

    // Delete existing file
    const { error: deleteError } = await supabase.storage
      .from("documents")
      .remove([revisionData.file_path]);

    if (deleteError) {
      throw deleteError;
    }

    // Upload new file
    const filePath = `${tenantId}/${revisionData.id}/${input.file.name}`;
    const { error: newFileError } = await supabase.storage
      .from("documents")
      .upload(filePath, input.file);

    if (newFileError) {
      throw newFileError;
    }

    // Update document revision with file path and document ID
    const { error: updateError } = await supabase
      .from("document_revisions")
      .update({ file_path: filePath, document_id: input.id })
      .eq("id", revisionData.id);

    if (updateError) {
      throw updateError;
    }
  }

  const { error } = await supabase
    .from("documents")
    .update({
      title: input.name,
      description: input.description,
      owned_by: input.owner,
      process_id: input.processId,
    })
    .eq("id", input.id)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useEditDocument = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: EditDocumentInputs) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return editDocument(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
