import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Process } from "../queries/processes";

const deleteProcess = async (process: Process) => {
  const { error } = await supabase
    .from("processes")
    .delete()
    .eq("id", process.id)
    .eq("tenant_id", process.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteProcess = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: deleteProcess,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
