import { useTenantContext } from "@/contexts/TenantContext";
import { useTenants } from "@/state/queries/tenants";
import { Button, DropdownMenu, Text } from "@radix-ui/themes";
import {
  IconChevronDown,
  IconSettings,
  IconUsersPlus,
} from "@tabler/icons-react";
import { Link, useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";
import InviteTeammates from "./InviteTeammates";

const WorkspaceMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { tenant } = useTenantContext();
  const { data: tenants } = useTenants();

  const otherTenants = Object.values(tenants ?? {}).filter(
    (t) => t.slug !== tenant.slug
  );

  const maybeOtherTenantsSubMenu =
    otherTenants.length > 0 ? (
      <>
        <DropdownMenu.Separator />
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>Switch Workspace</DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            {otherTenants.map((t) => (
              <DropdownMenu.Item
                key={t.slug}
                onClick={() => {
                  setMenuOpen(false);
                  navigate({
                    to: "/$tenantSlug",
                    params: { tenantSlug: t.slug },
                  });
                }}
              >
                <Link
                  to="/$tenantSlug"
                  params={{ tenantSlug: t.slug }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t.name}
                </Link>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
      </>
    ) : null;

  return (
    <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenu.Trigger>
        <Button variant="ghost" color="gray">
          <img
            src="/Logo.svg"
            alt="Logo"
            style={{ width: "18px", height: "18px" }}
          />
          <Text size="3" weight="bold">
            {tenant.name}
          </Text>
          <IconChevronDown />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item>
          <IconSettings /> Workspace Settings
        </DropdownMenu.Item>
        <InviteTeammates
          trigger={
            <DropdownMenu.Item onSelect={(e) => e.preventDefault()}>
              <IconUsersPlus /> Invite Teammates
            </DropdownMenu.Item>
          }
        />
        {maybeOtherTenantsSubMenu}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default WorkspaceMenu;
