import PageTitle from "@/components/PageTitle";
import { AddProcessAction } from "@/components/processes/AddProcessAction";
import { DeleteProcessAction } from "@/components/processes/DeleteProcessAction";
import { EditProcessAction } from "@/components/processes/EditProcessAction";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { UserRenderer } from "@/components/tables/renderers";
import { Process, useProcesses } from "@/state/queries/processes";
import { Button, Flex } from "@radix-ui/themes";
import { IconFilter, IconSortDescending } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import {
  ColDef,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute("/_app/$tenantSlug/processes/")({
  beforeLoad: () => {
    return {
      getTitle: () => "",
    };
  },
  component: ProcessesIndex,
});

function ProcessesIndex() {
  const [selectedProcesses, setSelectedProcesses] = useState<Process[]>([]);
  const resetSelectedProcesses = () => setSelectedProcesses([]);

  return (
    <Flex direction="column" gap="2">
      <PageTitle
        title="Processes"
        description="Define business processes in Meridian to execute workflows while automating compliance."
      />
      <ProcessesToolbar
        selectedProcesses={selectedProcesses}
        resetSelectedProcesses={resetSelectedProcesses}
      />
      <ProcessesTable onSelectionChange={setSelectedProcesses} />
    </Flex>
  );
}

const ProcessesToolbar: React.FC<{
  selectedProcesses: Process[];
  resetSelectedProcesses: () => void;
}> = ({ selectedProcesses, resetSelectedProcesses }) => {
  return (
    <Flex justify="between" align="center" py="2">
      <Flex gap="2">
        <Button variant="outline" color="gray">
          <IconSortDescending />
          Sort
        </Button>
        <Button variant="outline" color="gray">
          <IconFilter />
          Filter
        </Button>
      </Flex>
      <ProcessActions
        selectedProcesses={selectedProcesses}
        resetSelectedProcesses={resetSelectedProcesses}
      />
    </Flex>
  );
};

const ProcessActions: React.FC<{
  selectedProcesses: Process[];
  resetSelectedProcesses: () => void;
}> = ({ selectedProcesses, resetSelectedProcesses }) => {
  let actions = [];
  if (selectedProcesses.length === 1) {
    actions.push(
      <EditProcessAction key="edit" process={selectedProcesses[0]} />
    );
    actions.push(
      <DeleteProcessAction
        key="delete"
        process={selectedProcesses[0]}
        resetSelectedProcesses={resetSelectedProcesses}
      />
    );
  } else if (selectedProcesses.length === 0) {
    actions.push(<AddProcessAction key="add" />);
  }
  return <Flex gap="2">{actions}</Flex>;
};


function ProcessesTable({
  onSelectionChange,
}: {
  onSelectionChange: (processes: Process[]) => void;
}) {
  const { tenantSlug } = Route.useParams();
  const { data: processes, isLoading } = useProcesses();
  const columns: ColDef<Process>[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      cellRenderer: (props: ICellRendererParams) => (
        <Link
          to="/$tenantSlug/processes/$processId"
          params={{
            tenantSlug,
            processId: props.data.id,
          }}
        >
          {props.data.name}
        </Link>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "ownedBy",
      headerName: "Owner",
      cellRenderer: UserRenderer,
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
  ];
  const handleRowSelected = (event: RowSelectedEvent<Process>) => {
    const selectedRows = event.api.getSelectedRows();
    onSelectionChange(selectedRows);
  };

  return (
    <BaseAgGrid<Process>
      loading={isLoading}
      rowData={processes}
      columnDefs={columns}
      selection={{
        mode: "singleRow",
      }}
      onRowSelected={handleRowSelected}
    />
  );
}
