import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useEditProcess } from "@/state/mutations/editProcess";
import { Process } from "@/state/queries/processes";
import { Button, Dialog, Flex, Select } from "@radix-ui/themes";
import { IconEdit } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { useState } from "react";

interface EditProcessFormInputs {
  name: string;
  description: string;
  owner: string;
}

export const EditProcessAction: React.FC<{
  process: Process;
}> = ({ process }) => {
  const [open, setOpen] = useState(false);
  const { mutateAsync: editProcess } = useEditProcess();

  const form = useForm({
    defaultValues: {
      name: process.name,
      description: process.description ?? "",
      owner: process.ownedBy,
    },
    onSubmit: async ({ value }) => {
      await editProcess(
        {
          id: process.id,
          name: value.name,
          description: value.description,
          owner: value.owner,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconEdit />
          Edit
        </PermissionedButton>
      </Dialog.Trigger>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Edit process</Dialog.Title>
        <Form.Root reactForm={form}>
          <Flex direction="column" gap="4">
            <Form.Fields>
              <Form.TextField<EditProcessFormInputs>
                reactFormField={{ form, name: "name" }}
                fieldProps={{ label: "Process name", required: true }}
                placeholder="Enter process name"
                required
              />
              <Form.TextArea<EditProcessFormInputs>
                reactFormField={{ form, name: "description" }}
                fieldProps={{ label: "Description" }}
              />
              <Form.Select<EditProcessFormInputs>
                reactFormField={{ form, name: "owner" }}
                fieldProps={{ label: "Owner" }}
              >
                <Select.Content>
                  <Select.Item value="185f2f83-d63a-4c9b-b4a0-7e4a885799e2">
                    Vipul Shekhawat
                  </Select.Item>
                  <Select.Item value="987fcdeb-51a2-43f7-9abc-def123456789">
                    Alex Dees
                  </Select.Item>
                </Select.Content>
              </Form.Select>
            </Form.Fields>
            <Flex gap="3" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button type="submit">Save changes</Button>
            </Flex>
          </Flex>
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
