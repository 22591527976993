import { RequirementDialog } from "@/components/frameworks/RequirementDialog";
import NonIdealState from "@/components/NonIdealState";
import PageTitle from "@/components/PageTitle";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { EmptyValue, UserRenderer } from "@/components/tables/renderers";
import {
  complianceFrameworksQueryOptions,
  ComplianceMappedProcess,
  ComplianceRequirement,
  useComplianceFramework,
} from "@/state/queries/complianceFrameworks";
import { useTenantUsers } from "@/state/queries/tenantUsers";
import { THEME_COLOR } from "@/theme";
import { compareClauses } from "@/utils";
import {
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Progress,
  Link as RadixLink,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import { IconCalendarClock, IconPlus, IconSearch } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColDef, IRowNode } from "ag-grid-community";
import { useState } from "react";
import { z } from "zod";

export const Route = createFileRoute(
  "/_app/$tenantSlug/frameworks/$frameworkId"
)({
  validateSearch: z.object({
    clause: z
      .string()
      .regex(/^\d+(\.\d+)*$/)
      .optional(),
    status: z.enum(["Not started", "In progress", "Completed"]).optional(),
    owner: z.string().uuid().optional(),
    filter: z.string().optional(),
  }),
  beforeLoad: ({ params }) => ({
    getTitle: () => params.frameworkId,
  }),
  loader: async ({ context }) => {
    if (!context.tenant) {
      return;
    }
    await context.queryClient.ensureQueryData(
      complianceFrameworksQueryOptions(context.tenant.tenantId)
    );
  },
  component: Framework,
});

function Framework() {
  const { frameworkId } = Route.useParams();
  const { data: framework, isLoading } = useComplianceFramework(frameworkId);

  const done =
    framework?.requirements.filter((req) => req.status === "In progress")
      .length ?? 0;
  const total = framework?.requirements.length ?? 0;
  const percentage = done && total ? Math.round((done / total) * 100) : 0;

  return (
    <Flex direction="column" gap="5">
      <PageTitle
        isLoading={isLoading}
        title={framework?.displayName}
        tag="Compliance Framework"
        description={framework?.description}
      />
      <Grid columns="2" gap="4">
        <Card size="2">
          <Flex direction="column" gap="4">
            <Heading size="5">Framework Progress</Heading>
            <Flex direction="column" gap="2">
              <Text size="5" weight="bold">
                {percentage}%
              </Text>
              <Progress value={percentage} size="3" />
              <Text size="2" weight="medium" color="gray">
                {done} out of {total} requirements fulfilled
              </Text>
            </Flex>
          </Flex>
        </Card>
        <Card size="2">
          <Flex direction="column" gap="4">
            <Flex justify="between">
              <Heading size="5">Timeline</Heading>
              <Button variant="ghost" size="2">
                <IconPlus />
                Add event
              </Button>
            </Flex>
            <NonIdealState
              icon={<IconCalendarClock />}
              description="Add events to track your progress towards compliance."
              size="small"
            />
          </Flex>
        </Card>
      </Grid>
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="4">
          <Heading size="5">Requirements</Heading>
        </Flex>
        <RequirementsTable
          isLoading={isLoading}
          requirements={framework?.requirements ?? []}
        />
      </Flex>
    </Flex>
  );
}

const RequirementsTable = ({
  isLoading,
  requirements,
}: {
  isLoading?: boolean;
  requirements: ComplianceRequirement[];
}) => {
  const tenantSlug = Route.useParams().tenantSlug;
  const { filter, clause, status, owner } = Route.useSearch();
  const [selectedRequirement, setSelectedRequirement] = useState<
    ComplianceRequirement | undefined
  >(undefined);

  const columnDefs: ColDef<ComplianceRequirement>[] = [
    {
      field: "clause",
      headerName: "Clause",
      width: 100,
      sort: "asc",
      comparator: compareClauses,
    },
    {
      field: "title",
      headerName: "Title",
      width: 400,
      cellRenderer: ({
        data,
        value,
      }: {
        data: ComplianceRequirement;
        value: string;
      }) => {
        // TODO radix links are being weird, figure this out later
        return (
          <RadixLink
            underline="hover"
            weight="medium"
            onClick={() => setSelectedRequirement(data)}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>{value}</a>
          </RadixLink>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      cellRenderer: ({ value }: { value: ComplianceRequirement["status"] }) => {
        const badgeColor =
          value === "Not started"
            ? "gray"
            : value === "In progress"
              ? THEME_COLOR
              : "green";
        return (
          <Badge color={badgeColor} size="2" radius="large">
            {value}
          </Badge>
        );
      },
    },
    {
      field: "processes",
      headerName: "Mapped Process",
      width: 200,
      cellRenderer: ({ value }: { value: ComplianceMappedProcess[] }) => {
        const process = value[0];
        if (process) {
          return (
            <Link
              to="/$tenantSlug/processes/$processId"
              params={{
                tenantSlug,
                processId: process.id,
              }}
            >
              {process.name}
            </Link>
          );
        }
        return <EmptyValue text="No process mapped" />;
      },
    },
    {
      headerName: "Process Owner",
      flex: 1,
      valueGetter: (params) => params.data?.processes[0]?.owner,
      cellRenderer: UserRenderer,
    },
  ];

  const ribbon = (
    <Flex gap="3" align="center">
      <SearchField />
      <ClauseSelect requirements={requirements} />
      <StatusSelect />
      <OwnerSelect />
    </Flex>
  );

  const isExternalFilterPresent = !!(filter || clause || status || owner);
  const doesExternalFilterPass = (
    node: IRowNode<ComplianceRequirement>
  ): boolean => {
    if (clause && !node.data?.clause.startsWith(clause)) {
      return false;
    }
    if (status) {
      if (status === "Not started" && node.data?.status !== "Not started") {
        return false;
      }
      if (status === "In progress" && node.data?.status !== "In progress") {
        return false;
      }
      if (status === "Completed" && node.data?.status !== "Completed") {
        return false;
      }
    }
    if (owner && owner !== "all" && node.data?.processes[0]?.owner !== owner) {
      return false;
    }
    return true;
  };

  return (
    <>
      {ribbon}
      <BaseAgGrid
        loading={isLoading}
        rowData={requirements}
        columnDefs={columnDefs}
        quickFilterText={filter ?? ""}
        suppressCellFocus
        isExternalFilterPresent={() => isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
      />
      <RequirementDialog
        requirement={selectedRequirement}
        onClose={() => setSelectedRequirement(undefined)}
      />
    </>
  );
};

const SearchField = () => {
  const navigate = Route.useNavigate();
  const { filter } = Route.useSearch();

  return (
    <TextField.Root
      placeholder="Search..."
      value={filter ?? ""}
      onChange={(e) => {
        navigate({
          search: (prev) => ({
            ...prev,
            filter: e.target.value || undefined,
          }),
          replace: true,
        });
      }}
    >
      <TextField.Slot>
        <IconSearch />
      </TextField.Slot>
    </TextField.Root>
  );
};

const ClauseSelect = ({
  requirements,
}: {
  requirements: ComplianceRequirement[];
}) => {
  const navigate = Route.useNavigate();
  const { clause } = Route.useSearch();

  const topLevelRequirements = [
    { clause: "4", title: "Context of the Organization" },
    { clause: "5", title: "Leadership" },
    { clause: "6", title: "Planning" },
    { clause: "7", title: "Support" },
    { clause: "8", title: "Operation" },
    { clause: "9", title: "Performance Evaluation" },
    { clause: "10", title: "Improvement" },
  ];

  return (
    <Select.Root
      value={clause ?? ""}
      onValueChange={(value) => {
        navigate({
          search: (prev) => ({
            ...prev,
            clause: value === "all" ? undefined : value.toString(),
          }),
          replace: true,
        });
      }}
    >
      <Select.Trigger placeholder="Clause" />
      <Select.Content>
        <Select.Item value="all">Clause: All</Select.Item>
        {topLevelRequirements.map((req) => (
          <Select.Item key={req.clause} value={req.clause}>
            {req.clause}. {req.title}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const StatusSelect = () => {
  const navigate = Route.useNavigate();
  const { status } = Route.useSearch();

  return (
    <Select.Root
      value={status ?? ""}
      onValueChange={(value) => {
        navigate({
          search: (prev) => ({
            ...prev,
            status:
              value === "all"
                ? undefined
                : (value as "Not started" | "In progress" | "Completed"),
          }),
          replace: true,
        });
      }}
    >
      <Select.Trigger placeholder="Status" />
      <Select.Content>
        <Select.Item value="all">Status: All</Select.Item>
        <Select.Item value="Not started">Not started</Select.Item>
        <Select.Item value="In progress">In progress</Select.Item>
        <Select.Item value="Completed">Completed</Select.Item>
      </Select.Content>
    </Select.Root>
  );
};

const OwnerSelect = () => {
  const navigate = Route.useNavigate();
  const { owner } = Route.useSearch();
  const { data: users } = useTenantUsers();

  return (
    <Select.Root
      value={owner ?? ""}
      onValueChange={(value) => {
        navigate({
          search: (prev) => ({
            ...prev,
            owner: value === "all" ? undefined : value,
          }),
          replace: true,
        });
      }}
    >
      <Select.Trigger placeholder="Owner" />
      <Select.Content>
        <Select.Item value="all">Owner: All</Select.Item>
        {Object.values(users ?? {}).map((user) => (
          <Select.Item key={user.userId} value={user.userId}>
            {user.fullName ?? user.email}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};
