import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import { BadgeRenderer } from "@/components/tables/renderers";
import TabTitle from "@/components/TabTitle";
import {
  RECORD_TYPES,
  RecordField,
  recordFieldTypeToIcon,
  recordFieldTypeToLabel,
  RecordType,
} from "@/demoData";
import {
  Box,
  Button,
  Dialog,
  DropdownMenu,
  Flex,
  Heading,
} from "@radix-ui/themes";
import {
  IconBrandAirtable,
  IconBrandGoogleDrive,
  IconBrandMonday,
  IconCubePlus,
  IconFileSpreadsheet,
  IconPackageImport,
  IconPlus,
  IconSquareRoundedChevronsRight,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/processes/$processId/record-types"
)({
  beforeLoad: () => {
    return {
      getTitle: () => "Record Types",
    };
  },
  component: ProcessRecordTypes,
});

function ProcessRecordTypes() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TabTitle title="Record Types">
        <ImportRecordTypeMenu />
        <Button>
          <IconCubePlus />
          Add record type
        </Button>
      </TabTitle>
      <ProcessRecordTypesTable setOpen={setOpen} />
      <ProcessRecordTypeDialog
        isOpen={open}
        setOpen={setOpen}
        recordType={RECORD_TYPES["design-plans"]}
      />
    </>
  );
}

function ImportRecordTypeMenu() {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button variant="soft">
          <IconPackageImport />
          Import record type
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item>
          <IconFileSpreadsheet />
          Import from file
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Label>Integrations</DropdownMenu.Label>
        <DropdownMenu.Item>
          <IconBrandAirtable />
          Airtable
        </DropdownMenu.Item>
        <DropdownMenu.Item>
          <IconSquareRoundedChevronsRight />
          Jira
        </DropdownMenu.Item>
        <DropdownMenu.Item>
          <IconBrandGoogleDrive />
          Google Sheets
        </DropdownMenu.Item>
        <DropdownMenu.Item>
          <IconBrandMonday />
          Monday.com
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Item>Advanced...</DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

function ProcessRecordTypesTable({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) {
  const recordType = RECORD_TYPES["design-plans"];
  const colDefs: ColDef<RecordType>[] = [
    {
      field: "name",
      headerName: "Record Type",
      width: 180,
      cellRenderer: ({ data }: { data: RecordType }) => (
        <Flex align="center" gap="2">
          <Box height="28px" style={{ color: "var(--gray-11)" }}>
            {data.icon}
          </Box>
          {data.name}
        </Flex>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 220,
    },
    {
      field: "source",
      headerName: "Source",
      width: 120,
      cellRenderer: BadgeRenderer,
      cellRendererParams: {
        color: "iris",
      },
    },
    {
      headerName: "Count",
      valueGetter: () => "4 records",
      width: 100,
    },
    {
      headerName: "Fields",
      cellRenderer: ({ data }: { data: RecordType }) => (
        <Flex gap="2">{data.fields.map((field) => field.name).join(", ")}</Flex>
      ),
      flex: 1,
    },
  ];

  return (
    <BaseAgGrid
      rowData={[recordType]}
      columnDefs={colDefs}
      selectableRows
      onRowClicked={() => setOpen(true)}
    />
  );
}
function ProcessRecordTypeDialog({
  recordType,
  isOpen,
  setOpen,
}: {
  recordType: RecordType;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Content maxWidth="900px">
        <Dialog.Title>Record Type: {recordType.name}</Dialog.Title>
        <Dialog.Description>{recordType.description}</Dialog.Description>
        <Flex direction="column" gap="2" my="2">
          <Flex justify="between">
            <Heading size="4">Fields</Heading>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button>
                  <IconPlus />
                  Add field
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.string}
                    String
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.longText}
                    Long Text
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.number}
                    Number
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.date}
                    Date
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.timestamp}
                    Timestamp
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.boolean}
                    Boolean
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon["file[]"]}
                    Files
                  </Flex>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <Flex align="center" gap="2">
                    {recordFieldTypeToIcon.enum}
                    Option list
                  </Flex>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Flex>
          <BaseAgGrid<RecordField>
            rowData={recordType.fields}
            selection={{
              mode: "singleRow",
            }}
            columnDefs={[
              {
                field: "name",
                headerName: "Field Name",
                width: 200,
              },
              {
                field: "type",
                headerName: "Type",
                cellRenderer: ({ data }: { data: RecordField }) => (
                  <Flex align="center" gap="2">
                    <Box height="28px" style={{ color: "var(--gray-11)" }}>
                      {recordFieldTypeToIcon[data.type]}
                    </Box>
                    {recordFieldTypeToLabel[data.type]}
                  </Flex>
                ),
                width: 140,
              },
              {
                field: "description",
                headerName: "Description",
                flex: 1,
              },
            ]}
            height="auto"
          />
        </Flex>
        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
