import { useTenantContext } from "@/contexts/TenantContext";
import { Button, ButtonProps, Tooltip } from "@radix-ui/themes";

type Permission =
  | "read.documents"
  | "write.documents"
  | "read.processes"
  | "write.processes"
  | "read.users"
  | "read.design_plans";

interface PermissionedButtonProps extends ButtonProps {
  permission: Permission;
}

export const PermissionedButton = ({
  permission,
  ...props
}: PermissionedButtonProps) => {
  const { tenant } = useTenantContext();
  const hasPermission = tenant.userPermissions.has(permission);

  const button = <Button disabled={!hasPermission} {...props} />;

  if (!hasPermission) {
    return (
      <Tooltip content="You do not have permission to perform this action.">
        {button}
      </Tooltip>
    );
  }

  return button;
};
