import {
  Button,
  Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";

function InviteTeammates({
  trigger,
  onOpenChange,
}: {
  trigger?: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
}) {
  return (
    <Dialog.Root onOpenChange={onOpenChange}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content size="2">
        <Dialog.Title>Invite Teammates</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Invite your colleagues to collaborate.
        </Dialog.Description>
        <Flex direction="column" gap="3">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Email Address
            </Text>
            <TextField.Root placeholder="colleague@example.com" size="2" />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Role
            </Text>
            <Select.Root defaultValue="editor">
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="viewer">Viewer</Select.Item>
                <Select.Item value="editor">Editor</Select.Item>
                <Select.Item value="admin">Admin</Select.Item>
              </Select.Content>
            </Select.Root>
          </label>
          <Flex gap="3" mt="4" justify="end">
            <Button size="2">Send Invitation</Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default InviteTeammates;
