import { useTenantContext } from "@/contexts/TenantContext";
import { Database } from "@/database/generated.types";
import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";
import { notFound } from "@tanstack/react-router";

export type DocumentStatus = Database["public"]["Enums"]["document_status"];
export type DocumentRevisionStatus =
  Database["public"]["Enums"]["document_revision_status"];

export interface DocumentRevision {
  id: string;
  status: DocumentRevisionStatus;
  revisionNumber: number | undefined;
  filePath: string;
  contentType: string;
  originalFilename: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  pendingReviewAt: string | undefined;
  approvedAt: string | undefined;
  approvedBy: string | undefined;
}

export interface Document {
  id: string;
  tenantId: string;
  status: DocumentStatus;
  processId: string;
  processName: string;
  type?: string;
  title: string;
  description: string;
  owner: string;
  revisions: DocumentRevision[];
}

const fetchDocuments = async (tenantId: string): Promise<Document[]> => {
  const { data, error } = await supabase
    .from("documents")
    .select(
      `
      id,
      tenant_id,
      title,
      description,
      owned_by,
      status,
      document_types (
        name
      ),
      processes (
        id,
        name
      ),
      document_revisions (
        id,
        revision_number,
        status,
        file_path,
        content_type,
        original_filename,
        created_by,
        updated_by,
        created_at,
        updated_at,
        pending_review_at,
        approved_at,
        approved_by
      )
    `
    )
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((document) => ({
    id: document.id,
    tenantId: document.tenant_id,
    status: document.status,
    processName: document.processes?.name ?? "",
    processId: document.processes?.id ?? "",
    type: document.document_types?.name,
    title: document.title,
    description: document.description ?? "",
    owner: document.owned_by,
    revisions: document.document_revisions.map((revision) => ({
      id: revision.id,
      status: revision.status,
      revisionNumber: revision.revision_number ?? undefined,
      filePath: revision.file_path,
      contentType: revision.content_type,
      originalFilename: revision.original_filename,
      createdBy: revision.created_by,
      updatedBy: revision.updated_by,
      createdAt: revision.created_at,
      updatedAt: revision.updated_at,
      pendingReviewAt: revision.pending_review_at ?? undefined,
      approvedAt: revision.approved_at ?? undefined,
      approvedBy: revision.approved_by ?? undefined,
    })),
  }));
};

export const documentsQueryOptions = (tenantId: string) =>
  queryOptions({
    queryKey: ["documents", tenantId],
    queryFn: () => fetchDocuments(tenantId),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });

export const useDocuments = (): UseQueryResult<Document[]> => {
  const tenant = useTenantContext();
  if (!tenant.tenant) {
    throw new Error("Tenant not found");
  }
  return useQuery(documentsQueryOptions(tenant.tenant.tenantId));
};

export const useDocument = (documentId: string) => {
  const { data: documents, ...rest } = useDocuments();

  if (rest.isLoading) {
    return {
      ...rest,
      data: undefined,
    };
  }

  const document = documents?.find((document) => document.id === documentId);

  if (!document) {
    throw notFound();
  }

  return {
    ...rest,
    data: document,
  };
};

export const useDocumentsByProcess = (processId: string) => {
  const { data: documents, ...rest } = useDocuments();
  return {
    ...rest,
    data: documents?.filter((document) => document.processId === processId),
  };
};
