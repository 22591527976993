import { Tenant } from "@/state/queries/tenants";
import { useRouter } from "@tanstack/react-router";
import { createContext, useContext, useEffect } from "react";

export interface TenantContext {
  tenant: Tenant;
}

const TenantContextInner = createContext<TenantContext>({} as TenantContext);

export function TenantProvider({
  tenant,
  children,
}: {
  tenant: Tenant;
  children: React.ReactNode;
}) {
  const router = useRouter();

  // Invalidate router so all routes are reloaded with the new tenant
  useEffect(() => {
    if (tenant) {
      router.invalidate();
    }
  }, [router, tenant]);

  return (
    <TenantContextInner.Provider value={{ tenant }}>
      {children}
    </TenantContextInner.Provider>
  );
}

export function useTenantContext() {
  const context = useContext(TenantContextInner);
  if (context === undefined) {
    throw new Error("useTenantContext must be used within a TenantProvider");
  }
  return context;
}
