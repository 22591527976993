import { HoverCard, Text } from "@radix-ui/themes";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
dayjs.extend(relativeTime);

const RelativeTime: React.FC<{ timestamp: string }> = ({ timestamp }) => {
  const relativeTime = dayjs().to(timestamp);
  const absoluteTime = dayjs(timestamp).format("MMMM D, YYYY h:mm A");

  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <Text style={{ cursor: "pointer" }}>{relativeTime}</Text>
      </HoverCard.Trigger>
      <HoverCard.Content>
        <Text>{absoluteTime}</Text>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

export default RelativeTime;
