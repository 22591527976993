import { documentsQueryOptions } from "@/state/queries/documents";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/$tenantSlug/documents")({
  beforeLoad: () => {
    return {
      getTitle: () => "Documents",
    };
  },
  loader: async ({ context }) => {
    if (!context.tenant) {
      return;
    }
    await context.queryClient.ensureQueryData(
      documentsQueryOptions(context.tenant.tenantId)
    );
  },
});
