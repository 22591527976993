import { Switch, SwitchProps } from "@radix-ui/themes";
import { DeepKeys, useField } from "@tanstack/react-form";
import { FormField } from "./FormField";
import { BaseFieldProps } from "./types";
import { hasError } from "./utils";

export interface BaseSwitchProps<TData>
  extends BaseFieldProps<TData>,
    SwitchProps {}

export function BaseSwitch<TData>(props: BaseSwitchProps<TData>) {
  const { reactFormField, fieldProps, ...switchProps } = props;
  const field = useField<TData, DeepKeys<TData>>(reactFormField);

  const required = fieldProps.required || switchProps.required;

  const error = hasError(field.state.meta);
  return (
    <FormField reactFormField={field} required={required} {...fieldProps}>
      <Switch
        id={field.name as string}
        name={field.name as string}
        value={field.state.value as string}
        onBlur={field.handleBlur}
        onCheckedChange={(checked) => field.handleChange(checked as any)}
        size="3"
        required={required}
        {...switchProps}
      />
    </FormField>
  );
}
