import { useProcesses } from "@/state/queries/processes";
import { Select } from "@radix-ui/themes";
import { PropsWithChildren } from "react";
import { BaseSelect, BaseSelectProps } from "./BaseSelect";

export interface ProcessSelectProps<TData> extends BaseSelectProps<TData> {}

export function ProcessSelect<TData>(
  props: PropsWithChildren<ProcessSelectProps<TData>>
) {
  const { data: processes } = useProcesses();

  return (
    <BaseSelect {...props}>
      <Select.Content>
        {processes?.map((process) => (
          <Select.Item key={process.id} value={process.id}>
            {process.name} ({process.id})
          </Select.Item>
        ))}
      </Select.Content>
    </BaseSelect>
  );
}
