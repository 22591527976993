import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useAddComplianceMapping } from "@/state/mutations/complianceMappings";
import { useComplianceFrameworks } from "@/state/queries/complianceFrameworks";
import { Process } from "@/state/queries/processes";
import { compareClauses } from "@/utils";
import { Dialog, Flex, Select } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { useMemo, useState } from "react";

interface AddComplianceMappingActionProps {
  process: Process;
}

interface AddComplianceMappingFormInputs {
  frameworkId: string;
  clause: string;
}

export const AddComplianceMappingAction: React.FC<AddComplianceMappingActionProps> = ({
  process,
}) => {
  const [open, setOpen] = useState(false);
  const { mutateAsync: addMapping } = useAddComplianceMapping();
  const { data: frameworks } = useComplianceFrameworks();

  const form = useForm({
    defaultValues: {
      frameworkId: "AS9100D",
      clause: "",
    },
    onSubmit: async ({ value }) => {
      await addMapping(
        {
          processId: process.id,
          frameworkId: value.frameworkId,
          clause: value.clause,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const clausesByFramework = useMemo(
    () =>
      frameworks?.reduce(
        (acc, framework) => {
          acc[framework.id] = framework.requirements.map((requirement) => ({
            id: requirement.clause,
            title: requirement.title,
          }));
          return acc;
        },
        {} as Record<string, { id: string; title: string }[]>
      ),
    [frameworks]
  );

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.processes" variant="soft">
          <IconPlus />
          Add compliance mapping
        </PermissionedButton>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Add compliance mapping</Dialog.Title>
        <Form.Root reactForm={form}>
          <Form.Fields>
            <Form.Select<AddComplianceMappingFormInputs>
              reactFormField={{ form, name: "frameworkId" }}
              fieldProps={{
                label: "Framework",
              }}
            >
              <Select.Content>
                <Select.Item value="AS9100D">AS9100D</Select.Item>
              </Select.Content>
            </Form.Select>

            <Form.Select<AddComplianceMappingFormInputs>
              reactFormField={{ form, name: "clause" }}
              fieldProps={{
                label: "Clause",
              }}
            >
              <form.Subscribe
                selector={(state) => [state.values.frameworkId]}
                children={([frameworkId]) => {
                  const clauses = clausesByFramework?.[frameworkId];
                  const selectedClauseIds = process.complianceMappings.map(
                    (m) => m.clause
                  );
                  const sortedClauses = clauses
                    ?.filter((c) => !selectedClauseIds.includes(c.id))
                    .sort((a, b) => compareClauses(a.id, b.id));
                  return (
                    <Select.Content>
                      {sortedClauses?.map((clause) => (
                        <Select.Item value={clause.id}>
                          {clause.id} - {clause.title}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  );
                }}
              />
            </Form.Select>
          </Form.Fields>

          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Flex gap="3" justify="end">
                <Form.CancelButton form={form} />
                <Form.SubmitButton
                  canSubmit={canSubmit}
                  isSubmitting={isSubmitting}
                >
                  Add mapping
                </Form.SubmitButton>
              </Flex>
            )}
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
