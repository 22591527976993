import PageTitle from "@/components/PageTitle";
import { complianceFrameworksQueryOptions } from "@/state/queries/complianceFrameworks";
import {
  Badge,
  Button,
  ButtonProps,
  Flex,
  RadioCards,
  Separator,
  Text,
} from "@radix-ui/themes";
import { IconCheck } from "@tabler/icons-react";
import { createFileRoute, Link } from "@tanstack/react-router";
import React, { useState } from "react";
import GapAnalysisDialog from "../../../components/dialogs/GapAnalysisDialog";
import { THEME_COLOR } from "../../../theme";
export const Route = createFileRoute("/_app/$tenantSlug/navigator")({
  beforeLoad: () => {
    return {
      getTitle: () => "Navigator",
    };
  },
  loader: async ({ context }) => {
    if (!context.tenant) {
      return;
    }
    await context.queryClient.ensureQueryData(
      complianceFrameworksQueryOptions(context.tenant.tenantId)
    );
  },
  component: Navigator,
});

function Navigator() {
  const { tenantSlug } = Route.useParams();

  const [selectedPhase, setSelectedPhase] = useState<string>("plan");
  return (
    <Flex direction="column" gap="5">
      <PageTitle
        title="Navigator"
        description="Your organization's route to achieving AS9100 certification."
      />
      <RadioCards.Root
        size="1"
        value={selectedPhase}
        onValueChange={setSelectedPhase}
      >
        <Flex gap="2">
          <PhaseRadioCard
            index="1"
            label="Prepare"
            value="prepare"
            selectedPhase={selectedPhase}
            isCompleted
          />
          <PhaseRadioCard
            index="2"
            label="Plan"
            value="plan"
            selectedPhase={selectedPhase}
          />
          <PhaseRadioCard
            index="3"
            label="Implement"
            value="implement"
            selectedPhase={selectedPhase}
            disabled
          />
          <PhaseRadioCard
            index="4"
            label="Audit"
            value="audit"
            selectedPhase={selectedPhase}
            disabled
          />
        </Flex>
      </RadioCards.Root>
      <Flex direction="column" width="600px" gap="3">
        {selectedPhase === "prepare" && (
          <>
            <Step
              number={1}
              title="Upload existing documents"
              description="Gather information about your organization's existing documents and procedures."
              isCompleted
              primaryAction={<Button>View documents</Button>}
            />
            <Step
              number={2}
              title="Prepare gap analysis"
              description="Identify the gaps between existing processes and compliance requirements."
              primaryAction={<GapAnalysisDialog />}
              isCompleted
              isLastStep
            />
          </>
        )}
        {selectedPhase === "plan" && (
          <>
            <Step
              number={1}
              title="Define Process: Control of Documents"
              description="Create your process for managing organizational documents."
              primaryAction={
                <Button asChild>
                  <Link to={`/${tenantSlug}/processes/document-control`}>
                    View Process
                  </Link>
                </Button>
              }
              isCompleted
            />
            <Step
              number={2}
              title="Define Process: Control of Records"
              description="Create your process for managing company records."
              primaryAction={
                <Button asChild>
                  <Link to={`/${tenantSlug}/processes/record-control`}>
                    View Process
                  </Link>
                </Button>
              }
              isCompleted
            />
            <Step
              number={3}
              title="Define Process: Design Changes Approval"
              description="Create your process for managing changes to engineering designs."
              primaryAction={
                <Button variant="soft" asChild>
                  <Link to={`/${tenantSlug}/processes/design-changes`}>
                    View Process
                  </Link>
                </Button>
              }
            />
            <Step
              number={4}
              title="Define Process: Purchasing"
              description="Create your process for purchasing products and services."
              primaryAction={<Button>Create Process</Button>}
            />
            <Step
              number={5}
              title="Define Process: Receiving"
              description="Create your process for receiving products and services."
              primaryAction={<Button>Create Process</Button>}
            />
            <Step
              number={6}
              title="Define Process: Context of the Organization"
              description="Create your process for managing the organization's context."
              primaryAction={<Button>Create Process</Button>}
            />
            <Step
              number={7}
              title="Define Process: Control of Non-Conforming Product"
              description="Create your process for managing non-conforming products."
              primaryAction={<Button>Create Process</Button>}
            />
            <Step
              number={8}
              title="Management Review"
              description="Review all defined processes with management and release for implementation."
              primaryAction={<Button>Upload meeting notes</Button>}
              isLastStep
            />
          </>
        )}
      </Flex>
    </Flex>
  );
}

function PhaseRadioCard({
  selectedPhase,
  value,
  index,
  label,
  disabled,
  isCompleted,
}: {
  selectedPhase: string;
  value: string;
  index: string;
  label: string;
  disabled?: boolean;
  isCompleted?: boolean;
}) {
  const isSelected = selectedPhase === value;
  return (
    <RadioCards.Item value={value} disabled={disabled}>
      <Flex align="center" gap="2">
        <Badge
          color={!isSelected ? "gray" : undefined}
          variant={isSelected || isCompleted ? "solid" : "soft"}
          style={{
            paddingLeft: isCompleted ? "2px" : undefined,
            paddingRight: isCompleted ? "2px" : undefined,
          }}
        >
          {isCompleted ? (
            <IconCheck size="12" style={{ height: "16px", width: "15px" }} />
          ) : (
            index
          )}
        </Badge>
        <Text weight="bold">{label}</Text>
      </Flex>
    </RadioCards.Item>
  );
}

interface StepProps {
  number: number;
  title: string;
  description: string;
  primaryAction?: React.ReactElement<ButtonProps>;
  isActive?: boolean;
  isCompleted?: boolean;
  isLastStep?: boolean;
}

function Step({
  number,
  title,
  description,
  isActive,
  isCompleted,
  isLastStep,
  primaryAction,
}: StepProps) {
  return (
    <Flex gap="2">
      <Flex direction="column" align="center" gap="1" minHeight="35px">
        <Badge
          size="2"
          radius="full"
          variant="solid"
          color={isActive || isCompleted ? THEME_COLOR : "gray"}
          // Override padding to make the badge square when a checkmark is present
          style={{
            paddingLeft: isCompleted ? "var(--space-1)" : undefined,
            paddingRight: isCompleted ? "var(--space-1)" : undefined,
          }}
        >
          {isCompleted ? <IconCheck size="12" /> : number}
        </Badge>
        {!isLastStep && <Separator orientation="vertical" size="4" />}
      </Flex>

      <Flex direction="column" align="start" flexGrow="1" gap="3">
        <Flex direction="column">
          <Text weight="bold" size="3" color={!isActive ? "gray" : undefined}>
            {title}
          </Text>
          {!isCompleted && (
            <>
              <Text size="2" color="gray">
                {description}
              </Text>
            </>
          )}
        </Flex>
        {(isActive || isCompleted) &&
          primaryAction &&
          React.cloneElement(primaryAction, {
            variant: isActive ? "solid" : "soft",
            color: isCompleted || isActive ? THEME_COLOR : "gray",
          })}
      </Flex>
    </Flex>
  );
}

export default Navigator;
