import { Button } from "@radix-ui/themes";
import { PropsWithChildren } from "react";

export interface SubmitButtonProps {
  canSubmit: boolean;
  isSubmitting: boolean;
}

export const SubmitButton: React.FC<PropsWithChildren<SubmitButtonProps>> = ({
  canSubmit,
  isSubmitting,
  children,
}) => {
  return (
    <Button type="submit" loading={isSubmitting} disabled={!canSubmit} size="3">
      {children}
    </Button>
  );
};
