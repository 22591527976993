import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface ModifyComplianceMappingInput {
  processId: string;
  frameworkId: string;
  clause: string;
}

const addComplianceMapping = async (
  input: ModifyComplianceMappingInput,
  tenantId: string
) => {
  const { error } = await supabase.from("process_compliance_mappings").insert({
    tenant_id: tenantId,
    process_id: input.processId,
    framework_id: input.frameworkId,
    clause: input.clause,
  });

  if (error) {
    throw error;
  }
};

const removeComplianceMapping = async (
  input: ModifyComplianceMappingInput,
  tenantId: string
) => {
  const { error } = await supabase
    .from("process_compliance_mappings")
    .delete()
    .eq("process_id", input.processId)
    .eq("framework_id", input.frameworkId)
    .eq("clause", input.clause)
    .eq("tenant_id", tenantId);

  if (error) {
    throw error;
  }
};

export const useAddComplianceMapping = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: ModifyComplianceMappingInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return addComplianceMapping(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};

export const useRemoveComplianceMapping = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: ModifyComplianceMappingInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return removeComplianceMapping(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
