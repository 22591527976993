import { PermissionedButton } from "@/components/PermissionedButton";
import { useRemoveComplianceMapping } from "@/state/mutations/complianceMappings";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";

interface RemoveComplianceMappingActionProps {
  processId: string;
  frameworkId: string;
  clause: string;
}

export const RemoveComplianceMappingAction: React.FC<
  RemoveComplianceMappingActionProps
> = ({ processId, frameworkId, clause }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: removeMapping,
    isPending,
    error,
    reset,
  } = useRemoveComplianceMapping();

  const onSubmit = () => {
    removeMapping(
      { processId, frameworkId, clause },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  const errorMessage = error
    ? "Failed to remove compliance mapping. Please try again later."
    : undefined;
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton
          permission="write.processes"
          variant="soft"
          color="gray"
        >
          <IconTrash />
          Remove mapping
        </PermissionedButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Remove compliance mapping</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? This compliance mapping will be removed from the
              process. You can always add it back later.
            </Text>
            {errorMessage && <Text color="red">{errorMessage}</Text>}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button color="iris" loading={isPending} onClick={onSubmit}>
            Remove mapping
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
