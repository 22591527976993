import { ApproveRevisionAction } from "@/components/documents/ApproveRevisionAction";
import { CreateDocumentRevisionAction } from "@/components/documents/CreateDocumentRevisionAction";
import { DeleteRevisionAction } from "@/components/documents/DeleteRevisionAction";
import { SendRevisionForReviewAction } from "@/components/documents/SendRevisionForReviewAction";
import { BaseAgGrid } from "@/components/tables/BaseAgGrid";
import {
  BadgeRenderer,
  FileLinkRenderer,
  TimestampRenderer,
  UserRenderer,
} from "@/components/tables/renderers";
import TabTitle from "@/components/TabTitle";
import {
  Document,
  DocumentRevision,
  useDocument,
} from "@/state/queries/documents";
import { createFileRoute } from "@tanstack/react-router";
import { ColDef } from "ag-grid-community";
import { useState } from "react";

export const Route = createFileRoute(
  "/_app/$tenantSlug/documents/$documentId/revisions"
)({
  beforeLoad: async () => {
    return {
      getTitle: () => "Revisions",
    };
  },
  component: DocumentRevisions,
});

function DocumentRevisions() {
  const { documentId } = Route.useParams();
  const { data: document } = useDocument(documentId);
  const [selectedRevision, setSelectedRevision] = useState<
    DocumentRevision | undefined
  >(undefined);

  return (
    <>
      <TabTitle title="Revisions">
        {document && (
          <DocumentRevisionActions
            document={document}
            selectedRevision={selectedRevision}
          />
        )}
      </TabTitle>
      <RevisionsTable onSelect={setSelectedRevision} />
    </>
  );
}

const DocumentRevisionActions: React.FC<{
  document: Document;
  selectedRevision: DocumentRevision | undefined;
}> = ({ document, selectedRevision }) => {
  if (document.status !== "Active") {
    return null;
  }

  if (!selectedRevision) {
    return <CreateDocumentRevisionAction document={document} />;
  }

  if (selectedRevision.status === "Released") {
    return null;
  }

  let actions = [
    <DeleteRevisionAction
      key="delete"
      document={document}
      revisionId={selectedRevision.id}
    />,
  ];
  if (selectedRevision.status === "Draft") {
    actions.push(
      <SendRevisionForReviewAction
        key="send-for-review"
        document={document}
        revisionId={selectedRevision.id}
        isInitialRevision={false}
      />
    );
  }

  if (selectedRevision.status === "Pending review") {
    actions.push(
      <ApproveRevisionAction
        key="approve"
        document={document}
        revisionId={selectedRevision.id}
        isInitialRevision={false}
      />
    );
  }

  return <>{actions}</>;
};

const RevisionsTable: React.FC<{
  onSelect: (revision: DocumentRevision) => void;
}> = ({ onSelect }) => {
  const { documentId } = Route.useParams();
  const { data: document, isLoading } = useDocument(documentId);

  const columnDefs: ColDef<DocumentRevision>[] = [
    {
      headerName: "Status",
      field: "status",
      cellRenderer: BadgeRenderer,
      width: 130,
      sort: "asc",
      comparator: (valueA: string, valueB: string) => {
        const statusOrder: Record<string, number> = {
          "Pending review": 0,
          Draft: 1,
          Released: 2,
        };
        return statusOrder[valueA] - statusOrder[valueB];
      },
    },
    {
      headerName: "Number",
      field: "revisionNumber",
      cellRenderer: BadgeRenderer,
      width: 100,
      sort: "desc",
    },
    {
      headerName: "Author",
      field: "createdBy",
      cellRenderer: UserRenderer,
      width: 180,
    },
    {
      headerName: "Approver",
      field: "approvedBy",
      cellRenderer: UserRenderer,
      width: 180,
    },
    {
      headerName: "Created",
      field: "createdAt",
      cellRenderer: TimestampRenderer,
      width: 160,
    },
    {
      headerName: "Updated",
      field: "updatedAt",
      cellRenderer: TimestampRenderer,
      width: 160,
    },
    {
      headerName: "Released",
      field: "approvedAt",
      cellRenderer: TimestampRenderer,
      width: 160,
    },
    {
      headerName: "File",
      field: "filePath",
      cellRenderer: FileLinkRenderer,
      flex: 1,
    },
  ];

  return (
    <BaseAgGrid
      columnDefs={columnDefs}
      loading={isLoading}
      rowData={document?.revisions ?? []}
      selection={{
        mode: "singleRow",
      }}
      onSelectionChanged={(event) => {
        onSelect(event.api.getSelectedRows()[0]);
      }}
    />
  );
};
