import { createFileRoute, redirect } from "@tanstack/react-router";

// Always redirect to /frameworks page
export const Route = createFileRoute("/_app/$tenantSlug/")({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: `/${params.tenantSlug}/frameworks/AS9100D`,
      replace: true,
    });
  },
});
