import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface DeleteRevisionInput {
  id: string;
  tenantId: string;
}

const deleteRevision = async (input: DeleteRevisionInput) => {
  const { error } = await supabase
    .from("document_revisions")
    .delete()
    .eq("id", input.id)
    .eq("tenant_id", input.tenantId);

  if (error) {
    throw error;
  }
};

export const useDeleteRevision = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: DeleteRevisionInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return deleteRevision(input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
