import EmptyPage from "@/components/EmptyPage";
import {
  useNoTenantRedirect,
  useTenantRedirect,
} from "@/hooks/useTenantRedirect";
import { useUnauthenticatedRedirect } from "@/hooks/useUnauthenticatedRedirect";
import { Spinner } from "@radix-ui/themes";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: Index,
});

function Index() {
  useUnauthenticatedRedirect();
  useTenantRedirect();
  useNoTenantRedirect();

  return <EmptyPage icon={<Spinner />} />;
}
