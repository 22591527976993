import Form from "@/components/forms";
import { PermissionedButton } from "@/components/PermissionedButton";
import { useCreateDocumentRevision } from "@/state/mutations/documents/createDocumentRevision";
import { Document } from "@/state/queries/documents";
import { Dialog, Flex } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";

interface CreateDocumentRevisionInputs {
  file: File | null;
}

// TODO dedupe this
const allowedMimeTypes = new Set([
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
]);

export const CreateDocumentRevisionAction: React.FC<{ document: Document }> = ({
  document,
}) => {
  const [open, setOpen] = useState(false);
  const {
    mutateAsync: createDocumentRevision,
    error: createDocumentRevisionError,
    reset: resetMutation,
  } = useCreateDocumentRevision();

  const form = useForm<CreateDocumentRevisionInputs, any>({
    defaultValues: {
      file: null,
    },
    validatorAdapter: zodValidator(),
    validators: {
      onChange: z.object({
        file: z
          .instanceof(File, { message: "File is required" })
          .refine((file) => {
            if (!file) return true;
            if (allowedMimeTypes.has(file.type)) return true;
            return false;
          }, "Allowed formats: pdf, doc(x), xls(x), ppt(x)"),
      }),
    },
    onSubmit: async ({ value }) => {
      await createDocumentRevision(
        {
          ...value,
          document,
        },
        { onSuccess: () => setOpen(false) }
      );
    },
  });

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    form.reset();
    resetMutation();
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <PermissionedButton permission="write.documents" variant="soft">
          <IconPlus />
          Create revision
        </PermissionedButton>
      </Dialog.Trigger>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Create revision</Dialog.Title>
        <Form.Root reactForm={form}>
          <Form.Fields>
            <Form.FileUpload<CreateDocumentRevisionInputs>
              reactFormField={{ form, name: "file" }}
              fieldProps={{
                label: "Upload file",
                required: true,
              }}
            />
          </Form.Fields>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <>
                {createDocumentRevisionError && (
                  <Form.Error
                    message={`Failed to create new revision: ${createDocumentRevisionError.message}`}
                  />
                )}
                <Flex gap="3" justify="end">
                  <Form.CancelButton form={form} />
                  <Form.SubmitButton
                    canSubmit={canSubmit}
                    isSubmitting={isSubmitting}
                  >
                    Create revision
                  </Form.SubmitButton>
                </Flex>
              </>
            )}
          />
        </Form.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
};
