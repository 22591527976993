import { useTenantContext } from "@/contexts/TenantContext";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateProcessInput {
  id: string;
  name: string;
  description?: string;
  owner: string;
}

const createProcess = async (input: CreateProcessInput, tenantId: string) => {
  const { error } = await supabase.from("processes").insert({
    id: input.id,
    name: input.name,
    description: input.description,
    owned_by: input.owner,
    tenant_id: tenantId,
  });

  if (error) {
    throw error;
  }
};

export const useCreateProcess = () => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();

  return useMutation({
    mutationFn: (input: CreateProcessInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      return createProcess(input, tenant.tenant.tenantId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["processes", tenant.tenant?.tenantId],
      });
    },
  });
};
