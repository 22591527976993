import { createClient } from "@supabase/supabase-js";
import { Database } from "./database/generated.types";
import { RecordsDatabase } from "./database/types";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(
  supabaseUrl ?? "",
  supabaseAnonKey ?? ""
);

export const supabaseRecords = createClient<RecordsDatabase>(
  supabaseUrl ?? "",
  supabaseAnonKey ?? ""
);
