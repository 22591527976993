import { Box, Spinner } from "@radix-ui/themes";
import { IconTableOff } from "@tabler/icons-react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import cx from "classnames";
import { forwardRef, Ref } from "react";
import NonIdealState from "../NonIdealState";
import "./baseAgGrid.css";

export interface BaseAgGridProps<TData> extends AgGridReactProps<TData> {
  height?: "auto" | string;
  size?: "1" | "2" | "3";
  selectableRows?: boolean;
}

const BaseAgGridInner = <TData,>(
  props: BaseAgGridProps<TData>,
  ref?: Ref<AgGridReact<TData>>
): React.ReactElement => {
  const divHeight = props.height === undefined ? "400px" : props.height;
  const size = props.size ?? "2";
  const rowStyle = props.selectableRows ? { cursor: "pointer" } : undefined;
  return (
    <Box
      height={divHeight}
      className={cx("ag-theme-balham ag-grid-box", size && `size-${size}`)}
    >
      <AgGridReact<TData>
        ref={ref}
        animateRows={false}
        enableCellTextSelection={true}
        tooltipShowDelay={500}
        defaultColDef={{ useValueFormatterForExport: false }}
        domLayout={props.height === "auto" ? "autoHeight" : "normal"}
        noRowsOverlayComponent={() => (
          <NonIdealState icon={<IconTableOff />} title="No data" />
        )}
        loadingOverlayComponent={Spinner}
        rowStyle={rowStyle}
        suppressCellFocus={props.selectableRows}
        {...props}
      />
    </Box>
  );
};

// These type shenanigans are necessary to support having a generic type TData
// *and* a forwarded ref. See https://fettblog.eu/typescript-react-generic-forward-refs/.
export const BaseAgGrid = forwardRef(BaseAgGridInner) as <TData>(
  props: BaseAgGridProps<TData> & {
    ref?: React.ForwardedRef<AgGridReact<TData>>;
  }
) => ReturnType<typeof BaseAgGridInner>;
