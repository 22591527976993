import DetailCard from "@/components/DetailCard";
import CustomEdge from "@/components/workflow/CustomEdge";
import CustomNode from "@/components/workflow/CustomNode";
import { EdgeType, NodeType } from "@/components/workflow/types";
import { Badge, Box, Flex, IconButton, Text } from "@radix-ui/themes";
import {
  IconArrowsMaximize,
  IconCircle,
  IconCircleCheckFilled,
  IconCircleDashed,
  IconStatusChange,
  IconUserFilled,
} from "@tabler/icons-react";
import {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  MarkerType,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import classNames from "classnames";
import { useEffect } from "react";
import ActionNode from "./ActionNode";
import StatusNode from "./StatusNode";
import css from "./Workflow.module.scss";

const UserDescription: React.FC<{ description: string }> = ({
  description,
}) => {
  return (
    <Flex gap="1">
      <Badge variant="soft" color="gray">
        <IconUserFilled />
      </Badge>
      <Text size="1" color="gray">
        {description}
      </Text>
    </Flex>
  );
};

const initialNodes: NodeType[] = [
  {
    type: "action",
    id: "1",
    position: { x: 0, y: 0 },
    data: {
      icon: <IconStatusChange />,
      title: "New design plan",
      description: <UserDescription description="Project Manager" />,
    },
  },
  {
    type: "status",
    id: "2",
    position: { x: 30, y: 100 },
    data: {
      icon: <IconCircleDashed />,
      title: "Draft",
      color: "gray",
    },
  },
  {
    type: "action",
    id: "4",
    position: { x: 0, y: 180 },
    data: {
      icon: <IconStatusChange />,
      title: "Add requirements matrix",
      description: <UserDescription description="Project Manager" />,
    },
  },
  {
    type: "status",
    id: "5",
    position: { x: 30, y: 300 },
    data: {
      icon: <IconCircle />,
      title: "Pending review",
      color: "cyan",
    },
  },
  {
    type: "action",
    id: "6",
    position: { x: 0, y: 380 },
    data: {
      icon: <IconStatusChange />,
      title: "Approve requirements",
      description: <UserDescription description="Quality Manager" />,
    },
  },
  {
    type: "status",
    id: "7",
    position: { x: 30, y: 480 },
    data: {
      icon: <IconCircleCheckFilled />,
      title: "Released for design",
      color: "iris",
    },
  },
];

const nodeTypes = {
  custom: CustomNode,
  status: StatusNode,
  action: ActionNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const initialEdges: EdgeType[] = [
  {
    type: "custom",
    id: "1-2",
    source: "1",
    target: "2",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    type: "custom",
    id: "2-4",
    source: "2",
    target: "4",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    type: "custom",
    id: "4-5",
    source: "4",
    target: "5",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    type: "custom",
    id: "5-6",
    source: "5",
    target: "6",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    type: "custom",
    id: "6-7",
    source: "6",
    target: "7",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
];

export const Workflow: React.FC<{
  isEditing: boolean;
  cancelSemaphore: number;
}> = ({ isEditing, cancelSemaphore }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [setNodes, setEdges, cancelSemaphore]);

  const onConnect = (connection: Connection) => {
    setEdges((eds) => addEdge(connection, eds));
  };

  return (
    <DetailCard
      title="Workflow"
      actions={
        <IconButton variant="ghost" color="gray">
          <IconArrowsMaximize />
        </IconButton>
      }
    >
      <Box style={{ width: "100%", height: "450px" }}>
        <ReactFlow<NodeType, EdgeType>
          className={classNames(css.workflow, {
            [css.editable]: isEditing,
          })}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          elementsSelectable={isEditing}
          nodesDraggable={isEditing}
          nodesConnectable={isEditing}
          edgesFocusable={isEditing}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          snapToGrid
          fitViewOptions={{ maxZoom: 0.75 }}
          proOptions={{ hideAttribution: true }}
        >
          <Background variant={BackgroundVariant.Dots} />
          <Controls showInteractive={false} />
        </ReactFlow>
      </Box>
    </DetailCard>
  );
};
