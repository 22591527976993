/**
 * Compares two clauses to determine their order. Assumes clauses are a dot-separated list of numbers.
 * @returns -1 if a is less than b, 1 if a is greater than b, and 0 if they are equal
 */
export const compareClauses = (a: string, b: string) => {
  const aParts = a.split(".").map(Number);
  const bParts = b.split(".").map(Number);

  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    const aVal = aParts[i] || 0;
    const bVal = bParts[i] || 0;
    if (aVal !== bVal) {
      return aVal - bVal;
    }
  }

  return 0;
};
