import { Flex, Heading } from "@radix-ui/themes";

interface TabTitleProps {
  title: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Full-width title for a tab. Children are rendered on the right side, meant to
 * be used for tab-level actions.
 */
export const TabTitle: React.FC<TabTitleProps> = ({ title, children }) => {
  return (
    <Flex align="center" justify="between" my="3">
      <Heading size="5">{title}</Heading>
      <Flex align="center" gap="2">
        {children}
      </Flex>
    </Flex>
  );
};

export default TabTitle;
