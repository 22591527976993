import { PermissionedButton } from "@/components/PermissionedButton";
import { useSendRevisionForReview } from "@/state/mutations/documents/sendRevisionForReview";
import { Document } from "@/state/queries/documents";
import { AlertDialog, Button, Flex, Text } from "@radix-ui/themes";
import { IconSend } from "@tabler/icons-react";
import { useState } from "react";

export const SendRevisionForReviewAction: React.FC<{
  document: Document;
  revisionId: string;
  isInitialRevision: boolean;
  onSuccess?: () => void;
}> = ({ document, revisionId, isInitialRevision, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const {
    mutate: sendRevisionForReview,
    isPending,
    error,
    reset,
  } = useSendRevisionForReview(isInitialRevision);

  const onSubmit = () => {
    sendRevisionForReview(
      { documentId: document.id, revisionId, tenantId: document.tenantId },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      reset();
    }
  };

  const entityName = isInitialRevision ? "document" : "revision";
  const errorMessage = error
    ? `Failed to send ${entityName} for review. Please try again later.`
    : undefined;
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>
        <PermissionedButton permission="write.documents" variant="soft">
          <IconSend />
          Send for Review
        </PermissionedButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Send document for review</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="2">
            <Text>
              Are you sure? Once sent for review, you won't be able to make
              further changes until the review is complete.
            </Text>
            {errorMessage && <Text color="red">{errorMessage}</Text>}
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <Button loading={isPending} onClick={onSubmit}>
            Send for review
          </Button>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
