import { supabase } from "@/supabaseClient";
import { queryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";

export interface DocumentType {
  id: string;
  name: string;
  prefix: string;
}

const fetchDocumentTypes = async (): Promise<DocumentType[]> => {
  const { data, error } = await supabase
    .from("document_types")
    .select("id, name, prefix");

  if (error) {
    throw error;
  }

  if (!data) {
    return [];
  }

  return data.map((dt) => ({
    id: dt.id,
    name: dt.name,
    prefix: dt.prefix,
  }));
};

export const documentTypesQueryOptions = () =>
  queryOptions({
    queryKey: ["documentTypes"],
    queryFn: fetchDocumentTypes,
    staleTime: Infinity,
    retry: false,
  });

export const useDocumentTypes = (): UseQueryResult<DocumentType[]> => {
  return useQuery(documentTypesQueryOptions());
};
