import { useTenantContext } from "@/contexts/TenantContext";
import { useUser } from "@/state/queries/user";
import { supabase } from "@/supabaseClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

interface ApproveRevisionInput {
  documentId: string;
  revisionId: string;
  tenantId: string;
}

const getNextRevisionNumber = async (documentId: string, tenantId: string) => {
  const { data, error } = await supabase
    .from("document_revisions")
    .select("revision_number")
    .eq("document_id", documentId)
    .eq("tenant_id", tenantId)
    .not("revision_number", "is", null)
    .order("revision_number", { ascending: false })
    .limit(1)
    .single();

  if (error) {
    throw error;
  }

  if (!data || !data.revision_number) {
    throw new Error("No revisions found");
  }

  return data.revision_number + 1;
};

const approveRevision = async (
  input: ApproveRevisionInput,
  currentUserId: string,
  isInitialRevision: boolean
) => {
  if (isInitialRevision) {
    const { error: countError, count: revisionCount } = await supabase
      .from("document_revisions")
      .select("id", { count: "exact" })
      .eq("document_id", input.documentId)
      .eq("tenant_id", input.tenantId);

    if (countError) {
      throw countError;
    }

    if (revisionCount && revisionCount > 1) {
      throw new Error("Document has multiple revisions");
    }
  }

  const nextRevisionNumber = isInitialRevision
    ? 1
    : await getNextRevisionNumber(input.documentId, input.tenantId);

  const { error: revisionError } = await supabase
    .from("document_revisions")
    .update({
      status: "Released",
      revision_number: nextRevisionNumber,
      approved_at: dayjs().toISOString(),
      approved_by: currentUserId,
    })
    .eq("id", input.revisionId)
    .eq("tenant_id", input.tenantId);

  if (revisionError) {
    throw revisionError;
  }

  if (isInitialRevision) {
    const { error: documentError } = await supabase
      .from("documents")
      .update({ status: "Active" })
      .eq("id", input.documentId)
      .eq("tenant_id", input.tenantId);

    if (documentError) {
      throw documentError;
    }
  }
};

export const useApproveRevision = (isInitialRevision: boolean) => {
  const queryClient = useQueryClient();
  const tenant = useTenantContext();
  const user = useUser();

  return useMutation({
    mutationFn: (input: ApproveRevisionInput) => {
      if (!tenant.tenant) {
        throw new Error("Tenant not found");
      }
      if (!user.data) {
        throw new Error("User not found");
      }
      return approveRevision(input, user.data.id, isInitialRevision);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", tenant.tenant?.tenantId],
      });
    },
  });
};
